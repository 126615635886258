
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { empresaId, admin, nombre } from "../utils/LocalStorage";
import { ProductService } from "../../service/ProductService";
import "./AbmEmp.css";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import Swal from 'sweetalert2'
import React, { useState, useEffect, useRef } from "react";

const AbmEmployee = () => {
  const [visible, setVisible] = useState(false);
  const [newCuit, setNewCuit] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newEmpresaId, setNewEmpresaId] = useState("");
  const [newName, setNewName] = useState("");
  const [newApellido, setNewApellido] = useState("");
  const dt = useRef(null);
  const toast = useRef(null);
  const [newAdmin, setNewAdmin] = useState(1);
  const [checked, setChecked] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [empresas, setEmpresas] = useState();
  const [selectedEmpresa, setSelectedEmpresa] = useState({});
  const productService = new ProductService(empresaId);
  const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [showError, setShowError] = useState(false)
  const [vacioBody, setVacioBody] = useState(false)

  useEffect(() => {
    productService.getAllUsers().then((value) => {
      const filteredUsers = value.filter((user) => user.empresaid === 1 && user.id !== 1); /* FILTRO PARA MOSTRAR USUARIOS QUE NO TENGAN EMPRESA ID 1 NI USERID 1 */
      setUsers(filteredUsers);
    });
    productService.getEmpresas().then((data) => {
      setEmpresas(data);
    });
  }, []);

  const handleTrash = (user) => {
    Swal.fire({
      title: `¿Está seguro de eliminar el usuario : ${user.nombre}  ${user.apellido} ?`,
      icon: 'warning',
      showDenyButton: true,
      denyButtonText: 'Cancelar',
      cancelButtonColor: 'red',
      confirmButtonColor: 'green',
      confirmButtonText: 'OK'

    }).then((result) => {
      if (result.isConfirmed) {

        productService.deleteUser(user.id).then((value) => {
          setUsers(users.filter((value => value.id != user.id)));
          Swal.fire({
            title: `Se ha eliminado correctamente`,
            icon: 'success',
            confirmButtonColor: 'green',
            confirmButtonText: 'OK'

          })
        })
      } else if (result.isDenied) {
        Swal.fire("Cambios descartados.")
      }
    })
  }

  const sendUser = () => {
    // pipa aca van los vlaores
    if (visible) {
      const bodyfull = {
        user: {
          cuit: newCuit,
          password: newPassword,
          email: newEmail,
          admin: newAdmin,
          empresaId: 1,
          nombre: newName,
          apellido: newApellido,
        },
      };
      const body = bodyfull
      productService.createUser(body).then((value) => {
        productService.getAllUsers().then((value) => {
          const filteredUsers = value.filter((user) => user.empresaid === 1 && user.id !== 1);
          setUsers(filteredUsers);
          closeDialog()
          Swal.fire({
            title: 'Se ha creado el usuario exitosamente!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'blue',
            confirmButtonText: 'OK'
          })
        });

      });
    } else {
      console.log("datos trabados");
    }
  };

  const isFormValid = () => {
    return (
      newCuit.trim() !== "" && newCuit.length >= 7 &&
      newPassword.trim() !== "" &&
      newEmail.trim() !== "" &&
      newName.trim() !== "" &&
      newApellido.trim() !== ""
    );
  };

  const actionBodyTemplate = (user) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-trash" onClick={(e) => handleTrash(user)} />
      </React.Fragment>
    );
  };
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">
        <p style={{color:"#ffffff"}}>Administracion de usuarios</p>
      </span>
      <Button icon="pi pi-user-plus" onClick={() => setVisible(true)} />
    </div>
  );
  const dialogfooter = (
    <>
      <Button disabled={!isFormValid()} label="Confirmar" onClick={sendUser} />
    </>
  );
  const handleChange = (e) => {
    setChecked(e.value);
    checked ? setNewAdmin(0) : setNewAdmin(1);
  };

  const handleNewCuitChange = (e) => {
    const formattedCuit = e.target.value.replace(/\D/g, "").slice(0, 11); // Elimina cualquier caracter no numérico y limita a 11 caracteres
    setNewCuit(formattedCuit);
    setShowError(formattedCuit.length < 7);
  };
  const validoNombre = () => {
    if (newName.trim() == "")
      return "p-error p-invalid"
  }
  const validoApellido = () => {
    if (newApellido.trim() == "")
      return "p-error p-invalid"
  }
  const validoPassword = () => {
    if (newPassword.trim() == "")
      return "p-error p-invalid"
  }
  const validoEmail = () => {
    if (newEmail.trim() == "")
      return "p-error p-invalid"
  }
  const closeDialog = () => {
    setVisible(false)
     setNewCuit("")
      setNewPassword("")
      setNewEmail("")
      setNewName("")
      setNewApellido("")
  }

  return (
    <div className="containerAbm">
      <div className="card">
        {/* TABLA DE USUARIOS > VALUE=DATA RESPONSE > FIELD=DATA*/}
        <DataTable
          value={users}
          ref={dt}
          header={header}
          selection={selectedUser}
          dataKey="id"
          tableStyle={{ minWidth: "70rem" }}

        >
          <Column field="nombre" header="Nombre"></Column>
          <Column field="apellido" header="Apellido"></Column>
          <Column field="cuit" header="DNI"></Column>
          <Column field="email" header="Email"></Column>
          <Column
            field="admin"
            header="Administrador"
            headerStyle={{ textAlign: "center" }}
            bodyClassName="text-center"
          ></Column>

          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{
              minWidth: "8rem",
              textAlign: "right",
            }}
          ></Column>
        </DataTable>
      </div>
      <div className="card flex justify-content-center">
        <Dialog
          className="adduser"
          header="Agregar usuario"
          visible={visible}
          footer={dialogfooter}
          style={{ width: "500px" }}
          onHide={() => closeDialog()}
        >
          <div className="flex flex-column gap-2">
            <div className="flex flex-column gap-2">
              <label className={validoNombre()}>Nombre:</label>
              <InputText
                aria-describedby="Nombre-help"
                onChange={(e) => setNewName(e.target.value)}
                className={validoNombre()}
              />
              {!validoNombre() ? "" : <small className="p-error"> *Campo requerido </small>}
              {/* {newName == "" ? <span style={{ color: "#e24c4c" }}>El campo no debe estar vacio</span> : ""} */}
            </div>

            <div className="flex flex-column gap-2">
              <label className={validoApellido()} htmlFor="username">Apellido:</label>
              <InputText
                id="username"
                aria-describedby="Apellido-help"
                onChange={(e) => setNewApellido(e.target.value)}
                className={validoApellido()}
              />
              {!validoApellido() ? "" : <small className="p-error"> *Campo requerido </small>}
              {/* {validoApellido() ? <span style={{ color: "#e24c4c" }}>El campo no debe estar vacio</span> : ""} */}
            </div>

            <div className="flex flex-column gap-2">
              <label className={newCuit == "" || showError ? "p-error" : ""}>DNI:</label>
              <InputText
                keyfilter="num"
                id="dni"
                aria-describedby="dni-help"
                onChange={handleNewCuitChange}
                className={newCuit == "" || showError ? "p-invalid" : ""}
                maxLength={8}
              />
              {newCuit == "" || showError ? <small className="p-error"> *El campo debe tener un minimo de 7 numeros  </small> : ""}

              {/* {newCuit == "" || showError ? <span style={{ color: "#e24c4c" }}>El DNI debe tener al menos 7 números y no estar vacio</span> : ""} */}
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="username" className={validoEmail()}>EMAIL:</label>
              <InputText
                id="username"
                aria-describedby="EMAIL-help"
                className={validoEmail()}
                onChange={(e) => {
                    setNewEmail(e.target.value);
                }}
                placeholder="ejemplo@mail.com"
              />
              {!validoEmail() ? "" : <small className="p-error"> *Campo requerido </small>}
              {/* {newEmail == "" ? <span style={{ color: "#e24c4c" }}>El campo no debe estar vacio</span> : ""} */}
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="password" className={newPassword == "" ? "p-error" : ""}>Contraseña:</label>
              <InputText
                id="password"
                type="password"
                aria-describedby="Password-help"
                className={validoPassword()}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {!validoPassword() ? "" : <small className="p-error"> *Campo requerido </small>}
              {/* {newPassword == "" ? <span style={{ color: "#e24c4c" }}>El campo no debe estar vacio</span> : ""} */}
              {/* <Dropdown
              value={selectedEmpresa}
              onChange={(e) => setSelectedEmpresa(e.value)}
              options={empresas}
              optionLabel="razon_social"
              placeholder="Empresa"
              className="w-full md:w-14rem"
              /> */}
              {/* <div className="checkadm">
              <p>Administrador</p>
              <InputSwitch
                checked={checked}
                onChange={(e) => handleChange(e)}
              />
              </div> */}
            </div>
          </div>
          <h6>
            {" "}
            *Todos los datos requeridos son obligatorios
          </h6>
        </Dialog>
      </div>
    </div>
  );
};

export default AbmEmployee;
