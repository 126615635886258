import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Link, useLocation } from "react-router-dom";
import { admin } from "../../components/utils/LocalStorage";
import { nombre, empresa } from "../../components/utils/LocalStorage";
import { dateLoggin } from "../../components/utils/LocalStorage";
import "../../index.css";
import { Logout } from '@mui/icons-material';

export default function AccountMenu() {
  const { logOut } = useContext(UserContext);
  const location = useLocation();

  const SesionSindicato = () => {
    if (admin) {
      return (
        <>
          <h5 style={{ fontSize: "10px", color: "black", marginLeft: "5px" }}>Sesión iniciada por S.O.I.M.P</h5>
        </>
      )
    }
  }

  const fechaLocalStorage = new Date(dateLoggin);
  const opcionesFecha = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const opcionesHora = { hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'America/Argentina/Buenos_Aires' };

  const fechaArgentina = fechaLocalStorage.toLocaleDateString('es-AR', opcionesFecha);
  const horaArgentina = fechaLocalStorage.toLocaleTimeString('es-AR', opcionesHora);

  const Nav = () => {
    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
      
      setActiveLink(location.pathname);
    }, [location]);

    return (
      <>
        <nav className="navbar">
          <ul className="navbar-nav">
            {admin ? (
              <li className="nav-item">
                <Link
                  to="/empresas"
                  className={`nav-link ${activeLink === '/empresas' ? 'active' : ''}`}
                >
                  Plantilla de empresas
                </Link>
              </li>
            ) : null}
            {!admin ? (
              <li className="nav-item">
                <Link
                  to="/empleados"
                  className={`nav-link ${activeLink === '/empleados' ? 'active' : ''}`}
                >
                  Plantilla de empleados
                </Link>
              </li>
            ) : null}

            <li className="nav-item">
              <Link
                to="/ddjj"
                className={`nav-link ${activeLink === '/ddjj' ? 'active' : ''}`}
              >
                Declaraciones juradas
              </Link>
            </li>
            {admin ? (
              <li className="nav-item">
                <Link
                  to="/intereses"
                  className={`nav-link ${activeLink === '/intereses' ? 'active' : ''}`}
                >
                  Gestión de intereses
                </Link>
              </li>
            ) : null}
            {admin ? (
              <li className="nav-item">
                <Link
                  to="/abmemp"
                  className={`nav-link ${activeLink === '/abmemp' ? 'active' : ''}`}
                >
                  Gestión de usuarios
                </Link>
              </li>
            ) : null}
            <li className="nav-item">
              <Link
                to="/perfil"
                className={`nav-link ${activeLink === '/perfil' ? 'active' : ''}`}
              >
                Editar Perfil
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/auth"
                className={`nav-link ${activeLink === '/auth' ? 'active' : ''}`}
                onClick={() => { logOut() }}
              >
                <Logout />
              </Link>
            </li>
          </ul>
        </nav>
      </>
    )
  }

  return (
    <>
      <Nav />
      <div style={{ display: "flex",  paddingTop:"5px" }}>
        <SesionSindicato />
        <h5 style={{ fontSize: "10px", color: "black", marginLeft: "10px" }}>-  Usuario: {empresa}  - Inicio de sesión: {fechaArgentina} - {horaArgentina} hs</h5>
      </div>
    </>
  );
}
