import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../index.css";
import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "../../service/ProductService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { empresaId } from "../utils/LocalStorage";
import "./TablaTrabajadores.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import { admin } from "../utils/LocalStorage";
import { Dropdown } from "primereact/dropdown";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo from "../utils/encabezado.png"
import pie from "../utils/pie.png"
import { SplitButton } from 'primereact/splitbutton';
import { PrimeIcons } from 'primereact/api';
import Alerta from "../utils/AlertExport";
import instructivo from "./modeloTrabajadores.png"
import NewDDJJ from "../Ddjj/NewDDJJ"
import { Alert } from "bootstrap";
pdfMake.vfs = pdfFonts.pdfMake.vfs;



const TablaTrabajadores = () => {
  let emptyEmployee = {
    nombre: "",
    apellido: "",
    ingresos_bruto: 0,
    email: "",
    inicio_actividad: "",
    empresaId: 0,
    cuil: 0,
    dni: 0,
    domicilio: "",
    telefono: 0,
    PersonalId: 0,
    genero: "",
    activo: ""


  };

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [dialogTrabajador, setDialogTrabajador] = useState(false);
  const [product, setProduct] = useState(emptyEmployee);
  const [employees, setEmployes] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submittedAlta, setSubmittedAlta] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedEstado, setEstado] = useState();
  const [selectedGenero, setSelectedGenero] = useState();
  const productService = new ProductService(empresaId);
  const [visible, setVisible] = useState(false);
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  const empresaid = urlParams.get("empresaid") || null;
  const razonSocialEmpresa = urlParams.get("razonsocial") || null;
  const cuitEmpresa = urlParams.get("cuit") || null;
  const [fecha, setFecha] = useState("")
  const [showErrorCuil, setShowErrorCuil] = useState(false)
  const [showErrorDni, setShowErrorDni] = useState(false)
  const [muestroTabla, setMuestroTabla] = useState(true)
  const [ddjjfinal, setddjjfinal] = useState(null)

  useEffect(() => {
    if (empresaid != null) {
      productService.getProducts(empresaid).then((data) => {
        setProducts(getCustomers(data));
      });
    } else {
      productService.getProducts().then((data) => {
        setProducts(getCustomers(data));
      });
    }
  }, [employees]);



  const getCustomers = (data) => {
    // Asegúrate de que 'data' sea un array, si no lo es, devuelve un array vacío
    const customers = Array.isArray(data) ? data : [];

    return customers.map((d) => {
      let cuil = d.cuil.toString();

      // Verifica si ya está formateado
      if (!/^(\d{2})-(\d+)-(\d{1})$/.test(cuil)) {
        d.cuil = `${cuil.slice(0, 2)}-${cuil.slice(2, -1)}-${cuil.slice(-1)}`;
      }

      return d;
    });
  };

  const hideDialog = () => {
    setSubmittedAlta(false);
    setSubmitted(false)
    setProductDialog(false);
    setProduct(emptyEmployee)
  };


  const hideDialogTrabajador = () => {
    setSubmittedAlta(false);
    setSubmitted(false)
    setDialogTrabajador(false);
  };

  const prueba = (value) => {
    const activeRows = value.filter(row => row.activoVisual === "Empleado Activo");
    const isSameSelection = activeRows?.length === selectedProducts?.length &&
      activeRows.every(row => selectedProducts.includes(row));
    setSelectedProducts(isSameSelection ? [] : activeRows);
  };




  const createDDJJ = async () => {
    const ddjj = {
      sumaSueldos: 0,
      empleadosId: "",
      monto: 0,
      mesTributo: "06/2022",
      empresaId: empresaId,
      rectificacionId: null,
      sueldos: ""
    };
    selectedProducts.forEach((element, index) => {
      ddjj.sumaSueldos += Number(element.ingresos_bruto);
      const sueldo = Number(element.ingresos_bruto); // Convertir a número
      if (!isNaN(sueldo)) { // Verificar si es un número válido
        if (index !== selectedProducts.length - 1) {
          ddjj.sueldos += sueldo + "|";
          ddjj.empleadosId += element.PersonalId + "|";
        } else {
          ddjj.empleadosId += element.PersonalId;
          ddjj.sueldos += sueldo;
        }
      }
    });

    ddjj.monto = (ddjj.sumaSueldos * 3) / 100;
    setddjjfinal(ddjj)
    setMuestroTabla(false)

  };

  const validateEmployee = () => {
    if (
      product.nombre === "" ||
      product.apellido === "" ||
      product.dni === "" ||
      product.cuil === "" ||
      product.ingresos_bruto === "" ||
      product.domicilio === "" ||
      product.genero === "" ||
      product.inicio_actividad === ""
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Ingreso incorrecto',
        text: 'Los campos obligatorios deben ser completados',
      });
      return false;
    } else if (product.cuil.length < 10) {
      return false;
    } else if (product.dni.length < 7) {
      return false;
    }
    return true;
  };


  const actualizarEmpleado = () => {
    setSubmittedAlta(true);
    setSubmitted(true);
    if (!validateEmployee()) return;
    const body = {
      employee: product,
    };
    body.employee.inicio_actividad = new Date(product.inicio_actividad).toISOString().split('T')[0];
    body.employee.cuil = product.cuil.replace(/-/g, '');
    productService
      .editarEmpleado(body)
      .then((response) => {
        if (response.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error al editar empleado',
            text: response.mensaje,
          });
          setProductDialog(false)
        } else {
          setProductDialog(false);
          Swal.fire({
            icon: 'success',
            title: 'Datos actualizados',

          });
          productService.getProducts().then((data) => {
            setProducts(getCustomers(data));
          });
        }
      })
      .catch((err) => {
      });
    setProduct(emptyEmployee);

  }

  const saveEmployee = () => {
    setSubmittedAlta(true);
    setSubmitted(true);
    if (!validateEmployee()) return;
    const body = {
      employee: product,
    };
    product.activo = 1;
    productService
      .agregarEmpleado(body)
      .then((response) => {        
        if (response.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error al agregar empleado',
            text: response.mensaje,
          });
          return
        } else {
          Swal.fire({
            title: 'Se ha agregado exitosamente!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'green',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              setProduct(getCustomers(emptyEmployee));
              hideDialogTrabajador();

            }
          });
          productService.getProducts().then((data) => {
            setProducts(getCustomers(data));
          });
        }
      })
      .catch((err) => {
      });
  };




  const agregarEmpleado = () => {
    setDialogTrabajador(true);
  };
  const editProduct = (product) => {
    setProductDialog(true);
    setProduct({ ...product });
  };

  const onCategoryChange = (e) => {
    let _product = { ...product };
    _product["category"] = e.value;
    setProduct(_product);
  };

  const onInputChange = (e, name) => {

    let val = (e.target && e.target.value) || "";

    let _product = { ...product };
    _product[`${name}`] = val;
    setProduct(_product);
  };

  const MenuAcciones = () => {
    const toast = useRef(null);

    const items = [
      {
        label: 'Agregar Empleado',
        icon: PrimeIcons.USER_PLUS,
        command: () => {
          agregarEmpleado()
        },
        visible: !admin
      },
      {
        label: 'Importar EXCEL',
        icon: PrimeIcons.USER_PLUS,
        command: () => {
          setVisible(true)
        },
        visible: !admin
      },
      {
        label: 'Exportar a EXCEL',
        icon: PrimeIcons.FILE_EXCEL,
        command: () => {
          exportExcel()
        }
      },
      {
        label: 'Exportar a PDF',
        icon: PrimeIcons.FILE_PDF,
        command: () => {
          exportPDF()
        }
      }
    ];

    return (
      <div>
        <Toast ref={toast}></Toast>
        <SplitButton label="ACCIONES" model={items} className="p-button-sm mr-2 mb-2"></SplitButton>
        <Stack spacing={2} direction="row">
          <Dialog header="Para poder importar trabajadores de un archivo de Excel, el mismo debe tener SOLAMENTE las siguientes columnas:" visible={visible} modal={false} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} onHide={() => setVisible(false)} id="modalImportar">
            <p> <strong> El formato de FECHA DE INGRESO debe ser dd/mm/aaaa</strong></p>
            <img src={instructivo} alt="" style={{ maxWidth: "750px" }} /> <br />

            <p> <strong> A continuación, puede descargar el modelo a partir del cual podrá agregar directamente los datos de los Trabajadores</strong></p>

            <Button className="p-button-sm mr-2 mb-2" variant="contained" onClick={exportColumnNamesToExcel} >Descargar modelo </Button>

            <h5>Seleccione el archivo de Excel que desea importar:</h5>
            <input
              type="file"
              name="upload"
              id="upload"
              onChange={readUploadFile}
            />
          </Dialog>
        </Stack>
      </div>
    )
  }

  /* FUNCION PARA EXPORTAR MODELO DE EXCEL PARA LUEGO IMPORTAR LOS EMPLEADOS */

  function exportColumnNamesToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Column Names");

    worksheet.addRow([
      "CUIL",
      "DNI",
      "APELLIDO",
      "NOMBRE",
      "GENERO",
      "SUELDO BRUTO",
      "FECHA DE INGRESO",
      "E-MAIL",
      "DOMICILIO",
      "TELEFONO",
      "ESTADO",
    ]);

    workbook.xlsx.writeBuffer().then((buffer) => {
      const data = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

      const filename = "modeloTablaTrabajadores.xlsx";
      saveAs(data, filename);
    });
  }



  const BtnsDDJJ = () => {
    const mensaje = () => {
      Swal.fire({
        icon: 'error',
        title: `NO SELECCIONÓ NINGÚN EMPLEADO`,
        text: `Debe seleccionar los empleados que integrarán la DDJJ `,
      })
    }

    const btnNuevaDDJJDisabled = (
      <>
        <div onClick={mensaje}>
          <Button label="GENERAR DECLARACION JURADA" className="p-button-sm p-button-disabled mr-2 mb-2" disabled />
        </div>

      </>
    );
    const btnNuevaDDJJEnabled = (

      <Button label="GENERAR DECLARACION JURADA" className="p-button-sm mr-2 mb-2" onClick={createDDJJ} />



    );
    if (selectedProducts === null) return btnNuevaDDJJDisabled;
    if (selectedProducts != null && selectedProducts?.length > 0)
      return btnNuevaDDJJEnabled;
    if (selectedProducts?.length === 0) return btnNuevaDDJJDisabled;
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid rgba(0,0,0,0.2)",
    borderWidth: "2px",
    boxShadow: 24,
    borderRadius: "12px",
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const comprobacionAdmin = () => {
    if (!admin) {
      return (
        <Column
          header="Editar"
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "1rem", }}
        ></Column>
      );
    }
  };

  const ariaLabel = { "aria-label": "description" };


  const readUploadFile = (e) => {
    e.preventDefault();
    import("xlsx").then((xlsx) => {
      if (e.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonExcel = xlsx.utils.sheet_to_json(worksheet, {
            raw: false,
            dateNF: "dd/mm/yyyy",
          });
  
          const isDNIInCUIL = (dni, cuil) => {
            let index = 0;
            
            for (let i = 0; i < cuil.length; i++) {
              if (index < dni.length && cuil[i] === dni[index]) {
                index++;
              }
            }
            
            return index === dni.length;
          };
          
          // Validar todos los empleados antes de continuar
          const allValid = jsonExcel.every(row => {
            const cuil = row["CUIL"] || '';
            const dni = row["DNI"] || '';
            return isDNIInCUIL(dni, cuil);
          });
          
          if (!allValid) {
            Swal.fire({
              icon: 'error',
              title: 'Error en la importación',
              text: 'Se encontraron discrepancias entre el DNI y CUIL de una o más personas, por favor revise el archivo.',
            });
            setVisible(false);
            return; // Detener el flujo si la validación falla
          }
  
          // Validar duplicados de DNI y CUIL
          const cuils = jsonExcel.map(row => row["CUIL"]).filter(cuil => cuil);
          const dnis = jsonExcel.map(row => row["DNI"]).filter(dni => dni);
  
          const hasDuplicate = (arr) => new Set(arr).size !== arr.length;
          const hasDuplicateCuil = hasDuplicate(cuils);
          const hasDuplicateDni = hasDuplicate(dnis);
  
          if (hasDuplicateCuil || hasDuplicateDni) {
            Swal.fire({
              icon: 'error',
              title: 'Error en la importación',
              text: `Se encontraron CUIT o DNI duplicados, por favor revise el archivo `,
            });
            setVisible(false);
            return; // Detener el flujo si hay duplicados
          }
  
          // Renombrar las columnas y formatear la fecha
          const renamedJsonExcel = jsonExcel.map((row) => {
            return {
              ...row,
              cuil: row["CUIL"],
              dni: row["DNI"],
              apellido: row["APELLIDO"],
              nombre: row["NOMBRE"],
              genero: row["GENERO"],
              ingresos_bruto: row["SUELDO BRUTO"],
              inicio_actividad: formatDate(row["FECHA DE INGRESO"]),
              email: row["E-MAIL"],
              domicilio: row["DOMICILIO"],
              telefono: row["TELEFONO"],
              activo: row["ESTADO"],
            };
          });
  
          console.table(renamedJsonExcel);
  
          let body = { employees: renamedJsonExcel, empresaId: empresaId };
          productService.importEmployee(body).then((actual) => {
            setVisible(false);
            setEmployes(actual);
            Swal.fire({
              icon: 'success',
              title: `Importación exitosa`,
              html: `
                <h5>Personas actualizadas: ${actual.actualizados}</h5>
                <h5>Personas agregadas: ${actual.insertados}</h5>`,
            });
          });
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    });
  };
  // Función para formatear la fecha a dd/mm/aaaa
  const formatDate = (date) => {
    const parts = date.split("/");
    // Asegurarse de tener el formato dd/mm/aaaa
    const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
    return formattedDate;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box
            sx={{
              display: "flex",
              ...style,
              width: 500,
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                mb: 2,
              }}
              component="h1"
              variant="h5"
            ></Typography>

            <TextField
              sx={{
                mb: "10px",
              }}
              InputLabelProps={{ shrink: true, style: { fontSize: "auto" } }}
              label="Nombre"
              defaultValue="Nombre"
              variant="filled"
            />

            <TextField
              sx={{
                mb: "10px",
              }}
              label="CUIL"
              id="filled-size-normal"
              defaultValue="CUIL"
              variant="filled"
            />

            <TextField
              sx={{
                mb: "10px",
              }}
              label="Remuneracion"
              id="filled-size-normal"
              defaultValue="Remuneracion"
              variant="filled"
            />
            <TextField
              sx={{
                mb: "10px",
              }}
              label="Remuneracion"
              id="filled-size-normal"
              defaultValue="genero"
              variant="filled"
            />
          </Box>
        </Modal>
      </React.Fragment>
    );
  };



  const BtnAcciones = () => {
    if (!admin) {
      return (
        <>
          <Stack spacing={2} direction="row">
            <BtnsDDJJ></BtnsDDJJ>
            <MenuAcciones />
          </Stack>
        </>
      );
    }
  };



  /* FUNCION PARA EXPORTAR SELECCION EN EXCEL   */

  const exportExcel = () => {
    if (selectedProducts == null || selectedProducts.length == 0) {
      Alerta()
    } else {


      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Datos seleccionados');

      // Establecer encabezados de columna
      const headers = [
        'CUIL',
        'DNI',
        'APELLIDO',
        'NOMBRE',
        'GENERO',
        'SUELDO BRUTO',
        'FECHA DE INGRESO',
        'DOMICILIO',
        'TELEFONO',
        'E-MAIL',
        'ESTADO'

      ];
      worksheet.addRow(headers);

      // Agregar filas de datos
      selectedProducts.forEach((customer) => {
        const rowData = [
          customer.cuil.replace(/-/g, '').toUpperCase(),
          customer.dni.toUpperCase(),
          customer.apellido.toUpperCase(),
          customer.nombre.toUpperCase(),
          customer.genero.toUpperCase(),
          customer.ingresos_bruto,
          new Date(customer.inicio_actividad),
          customer.domicilio.toUpperCase(),
          customer.telefono.toUpperCase(),
          customer.email.toUpperCase(),
          customer.activoVisual.toUpperCase(),
        ];
        worksheet.addRow(rowData);
      });

      // Establecer estilo para los encabezados de columna
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '0080ff' },
        };
      });


      // Ajustar el ancho de las columnas automáticamente
      worksheet.columns.forEach((column) => {
        column.width = 15;
      });

      // Generar el archivo Excel
      workbook.xlsx.writeBuffer().then((buffer) => {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, 'ListadoEmpleados.xlsx');
      });
    }
  }

  /* EXPORTAR A PDF */

  const exportPDF = async () => {
    if (selectedProducts == null || selectedProducts.length == 0) {
      Alerta();
      return; // Salir de la función si no hay productos seleccionados
    }

    try {
      // Cargar la imagen del logo
      const logoResponse = await fetch(logo);
      const logoBlob = await logoResponse.blob();
      const base64Logo = await convertirImagenABase64Async(logoBlob);

      // Cargar la imagen de pie
      const pieResponse = await fetch(pie);
      const pieBlob = await pieResponse.blob();
      const base64Pie = await convertirImagenABase64Async(pieBlob);

      const cellStyle = {
        fontSize: 8,
      };

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };

      const selectedProductsUpper = selectedProducts.map((customer) => ({
        cuil: customer.cuil.toUpperCase(),
        dni: customer.dni.toUpperCase(),
        apellido: customer.apellido.toUpperCase(),
        nombre: customer.nombre.toUpperCase(),
        genero: customer.genero.toUpperCase(),
        ingresos_bruto: customer.ingresos_bruto,
        inicio_actividad: formatDate(customer.inicio_actividad),
        domicilio: customer.domicilio.toUpperCase(),
        telefono: customer.telefono !== 0 ? customer.telefono.toUpperCase() : '',
        email: customer.email.toUpperCase(),
      }));

      const table = {
        headerRows: 1,
        widths: [60, 60, 60, 65, 55, 65, 50, 120, 50, 80],
        body: [
          [
            { text: 'CUIL', style: 'header' },
            { text: 'DNI', style: 'header' },
            { text: 'APELLIDO', style: 'header' },
            { text: 'NOMBRE', style: 'header' },
            { text: 'GÉNERO', style: 'header' },
            { text: 'SUELDO BRUTO', style: 'header' },
            { text: 'INGRESO', style: 'header' },
            { text: 'DOMICILIO', style: 'header' },
            { text: 'TELÉFONO', style: 'header' },
            { text: 'E-MAIL', style: 'header' },
          ],
          ...selectedProductsUpper.map((customer) => [
            { text: customer.cuil, style: cellStyle },
            { text: customer.dni, style: cellStyle },
            { text: customer.apellido, style: cellStyle },
            { text: customer.nombre, style: cellStyle },
            { text: customer.genero, style: cellStyle },
            { text: '$' + customer.ingresos_bruto, style: cellStyle },
            { text: customer.inicio_actividad, style: cellStyle },
            { text: customer.domicilio, style: cellStyle },
            { text: customer.telefono, style: cellStyle },
            { text: customer.email, style: cellStyle },
          ]),
        ],
        styles: {
          header: {
            fillColor: '#0080ff',
            color: '#ffffff',
            bold: true,
          },
        },
      };

      table.body[0].forEach(header => {
        header.fontSize = 9;
      });

      const content = [];
      let pageMargins = [15, 105, 0, 30]; // Margen inferior por defecto

      if (empresaId == 1) {
        content.push(
          { text: `EMPRESA: ${razonSocialEmpresa}`, alignment: 'left', margin: [0, 110, 0, 0], bold: true },
          { text: `CUIT: ${cuitEmpresa}`, alignment: 'left', margin: [0, 10, 0, 0], bold: true }
        );
        pageMargins = [15, 0, 0, 30]; // Actualizar margen superior si la condición se cumple
      }

      content.push({ table: table, layout: 'lightHorizontalLines', alignment: 'center', margin: [0, 10, 0, 0] });

      const docDefinition = {
        pageSize: 'A3',
        header: {
          image: base64Logo,
          width: 550,
          alignment: 'center'
        },
        content: content,
        footer: {
          image: base64Pie,
          width: 650,
          alignment: 'center'
        },
        pageMargins: pageMargins, // Usar los márgenes definidos anteriormente
      };

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBlob((blob) => {
        saveAs(blob, 'ListadoTrabajadores.pdf');
      });
    } catch (error) {
      console.error('Error al exportar PDF:', error);
      // Manejar el error según sea necesario
    }
  };


  // Función asincrónica para convertir una imagen a base64
  const convertirImagenABase64Async = async (imagen) => {
    return new Promise((resolve, reject) => {
      const lector = new FileReader();
      lector.readAsDataURL(imagen);
      lector.onload = () => {
        resolve(lector.result);
      };
      lector.onerror = (error) => {
        reject(error);
      };
    });
  };



  const CheckAdminAcciones = () => {
    if (admin) {
      return (
        <MenuAcciones />
      )
    }
  }

  const header = (
    <>
      <div className="table-header">
        <BtnAcciones />
        <CheckAdminAcciones />
        <h5 className="m-0">PLANTILLA DE EMPLEADOS {razonSocialEmpresa} </h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Busqueda avanzada"
          />
        </span>
      </div>
    </>
  );


  const productDialogFooter = (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        <Button
          label="Cancelar"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
        />
        <Button
          label="Actualizar datos"
          icon="pi pi-check"
          className="p-button-text"
          onClick={actualizarEmpleado}
        />
      </div>
    </React.Fragment>
  );

  const BtnGuardar = () => {
    return (
      <>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          className="p-button-success"
          onClick={saveEmployee}
        />
      </>)
    /*  );
   } */
  };

  const DialogTrabajadorFooter = (
    <React.Fragment>
      <div className="d-flex justify-content-between mt-3 mb-3">
        <Button
          label="Cancelar"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialogTrabajador}
        />
        <BtnGuardar />
      </div>
    </React.Fragment>
  );

  const handlerGenero = (value) => {
    setSelectedGenero(value);
    product.genero = value;
  }

  const handlerEstadoEmpleado = (value) => {
    setEstado(value);
    product.activo = value;

    if (product.activo === "Inactivo") {
      product.activo = 0;
      product.activoVisual = "Empleado Inactivo";
    } else {
      product.activo = 1;
      product.activoVisual = "Empleado Activo";
    }
  }
  const handleCuilChange = (e) => {
    const formattedCuil = e.target.value.replace(/\D/g, "").slice(0, 11); // Elimina cualquier caracter no numérico y limita a 11 caracteres
    onInputChange({ target: { value: formattedCuil } }, "cuil");
    setShowErrorCuil(formattedCuil.length < 10);
  };

  const handleDniChange = (e) => {
    const formattedDni = e.target.value.replace(/\D/g, "").slice(0, 8); // Elimina cualquier caracter no numérico y limita a 8 caracteres
    onInputChange({ target: { value: formattedDni } }, "dni");
    setShowErrorDni(formattedDni.length < 7);
  };

  return (
    <>
      {muestroTabla ?
        <div className="datatable-doc-demo">
          <div className="card">
            <DataTable
              value={products}
              selection={selectedProducts}
              scrollHeight="600px"
              paginator
              className="p-datatable-customers"
              header={header}
              rows={10}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10, 25, 50]}
              dataKey="PersonalId"
              rowHover
              onSelectionChange={(e) => prueba(e.value)}
              filterDisplay="menu"
              responsiveLayout="scroll"
              globalFilter={globalFilter}
              emptyMessage="No customers found."
              currentPageReportTemplate="Mostando {first} de {last} del total de {totalRecords} empleados">
              <Column
                isDataSelectable={(rowData) => rowData.activoVisual !== "Empleado Inactivo"}
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
                exportable={false}
              ></Column>
              <Column
                field="cuil"
                header="CUIL"
                sortable
                style={{ minWidth: "10rem" }}
              ></Column>
              <Column
                field="dni"
                header="DNI"
                sortable
                style={{ minWidth: "10rem" }}
              ></Column>
              <Column
                field="apellido"
                header="Apellido"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                field="nombre"
                header="Nombre"
                sortable
                style={{ minWidth: "12rem" }}></Column>
              <Column
                field="ingresos_bruto"
                body={(rowData) => {
                  const sueldoBruto = Number(rowData.ingresos_bruto);
                  return sueldoBruto.toLocaleString("es-AR", {
                    style: "currency",
                    currency: "ARS",
                  });
                }}
                header="Sueldo Bruto"
                sortable
                style={{ minWidth: "15rem" }}
              ></Column>
              <Column
                field="email"
                header="E-Mail"
                sortable
                style={{ minWidth: "15rem" }}
              ></Column>
              <Column
                field="domicilio"
                header="Domicilio"
                sortable
                style={{ minWidth: "15rem" }}
              ></Column>
              <Column
                field="telefono"
                header="Telefono"
                sortable
                style={{ minWidth: "15rem" }}
                body={(rowData) => rowData.telefono !== 0 ? rowData.telefono : ''}
              ></Column>
              <Column
                field="genero"
                header="Genero"
                sortable
                style={{ minWidth: "15rem" }}
              ></Column>
              <Column
                field="inicio_actividad"
                header="Fecha de ingreso"
                sortable
                style={{ minWidth: "15rem" }}
                body={(rowData) => {
                  const date = new Date(rowData.inicio_actividad);
                  return date.toLocaleDateString("es-AR");
                }}
              />
              <Column
                field="activoVisual"
                header="Activo"
                sortable
                style={{ minWidth: "15rem" }}
                body={(rowData) => rowData.activoVisual?.toUpperCase()}
              />
              {comprobacionAdmin()}
            </DataTable>
          </div>
        </div>
        :
        <div style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9998,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}>
          <NewDDJJ>{ddjjfinal}</NewDDJJ>
          <Button
            label="CANCELAR CREACION DE DDJJ"
            className="p-button-sm p-button-danger p-button-lg mr-2 mb-2"
            style={{ marginTop: "20px" }}
            onClick={() => {
              setMuestroTabla(true);
              setSelectedProducts(null);
            }}
            id="btnCancelarDDJJ"
          />
        </div>}

      {/* 
        
   TABLA EDITAR TRABAJADOR
        
        
 */}

      <Dialog
        visible={productDialog}
        style={{ width: "470px" }}
        header="Editar empleado"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            id="nombre"
            value={product.nombre}
            onChange={(e) => onInputChange(e, "nombre")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !product.nombre,
            })}
          />
          {submitted && !product.nombre && (
            <small className="p-error">Nombre Requerido</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="apellido">Apellido </label>
          <InputText

            id="apellido"
            value={product.apellido}
            onChange={(e) => onInputChange(e, "apellido")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !product.apellido,
            })}
          />
          {submitted && !product.apellido && (
            <small className="p-error">Apellido requerido</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="cuil">CUIL - sin guiones - </label>
          <InputText
            id="cuil"
            value={typeof product.cuil === 'string' ? product.cuil.replace(/-/g, "") : ""}
            onChange={(e) => onInputChange(e, "cuil")}
            keyfilter="num"
            required
            autoFocus
            maxLength={11}
            className={classNames({ "p-invalid": submitted && !product.cuil })}
          />
          {product.cuil && product.cuil.length < 10 && (
            <span className="p-error">El CUIL debe tener al menos 10 números</span>
          )}
          {submitted && !product.cuil && (
            <small className="p-error">CUIL requerido</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="dni">DNI - sin puntos -</label>
          <InputText

            id="dni"
            value={product.dni}
            onChange={(e) => onInputChange(e, "dni")}
            required
            keyfilter="num"
            autoFocus
            maxLength={8}
            className={classNames({ "p-invalid": submitted && !product.dni })}
          />
          {product.dni && product.dni.length < 7 && (
            <span className="p-error">El DNI debe tener al menos 7 números</span>
          )}
          {submitted && !product.dni && (
            <small className="p-error">DNI requerido</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="apellido">Teléfono</label>
          <InputText

            id="telefono"
            value={product.telefono}
            onChange={(e) => onInputChange(e, "telefono")}
            required
            autoFocus
          />
        </div>
        <div className="field">
          <label htmlFor="apellido">E-mail</label>
          <InputText

            id="mail"
            value={product.email}
            onChange={(e) => onInputChange(e, "email")}
            required
            autoFocus
          />
        </div>
        <div className="field">
          <label htmlFor="apellido">Domicilio</label>
          <InputText

            id="domicilio"
            value={product.domicilio}
            onChange={(e) => onInputChange(e, "domicilio")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !product.domicilio,
            })}
          />
          {submitted && !product.domicilio && (
            <small className="p-error">Domicilio requerido</small>
          )}
        </div>

        <div className="field">
          <label className="mb-2" htmlFor="Genero">Genero</label>
          <Dropdown
            value={product.genero}
            onChange={(e) => handlerGenero(e.value)}
            options={["MASCULINO", "FEMENINO", "NO BINARIO"]}
            placeholder="Genero"
            className="w-full "
          />

          {submitted && !product.genero && (
            <small className="p-error">genero requerido</small>
          )}
        </div>
        <div className="field">
          <label className="mb-2" htmlFor="Estado de actividad">Estado de actividad</label>
          <div className="card flex justify-content-center">
            <Dropdown value={product.activo == 0 ? "Inactivo" : "Activo"} onChange={(e) => handlerEstadoEmpleado(e.value)} options={["Activo", "Inactivo"]}
              placeholder="Seleccione un estado" className="w-full " />
          </div>
        </div>
        <div className="field">
          <label htmlFor="nombre">Sueldo Bruto</label>
          <InputText
            id="nombre"
            value={product.ingresos_bruto}
            keyfilter="num"
            onChange={(e) => onInputChange(e, "ingresos_bruto")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !product.ingresos_bruto,
            })}
          />
          {submitted && !product.ingresos_bruto && (
            <small className="p-error">Ingresos Requeridos</small>
          )}
        </div>

        <div id="testo">
          <div id="testoo">
            <label htmlFor="fechainicio">Fecha de ingreso</label>
            <input
              id="fechainicio"
              type="date"
              defaultValue={product.inicio_actividad ? new Date(product.inicio_actividad).toISOString().split('T')[0] : ''}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value + "T00:00:00");
                const currentDate = new Date();
                if (selectedDate > currentDate) {
                  e.target.value = "";
                  Swal.fire({
                    icon: 'error',
                    title: 'Selección incorrecta',
                    text: 'No se puede seleccionar una fecha futura',
                  });
                } else {
                  onInputChange(e, "inicio_actividad");
                }

              }} />
            {submitted && !product.inicio_actividad && (
              <small className="p-error">Fecha requerida</small>
            )}
          </div>

        </div>

      </Dialog>



      {/* 

TABLA AGREGAR NUEVO TRABAJADOR


*/}
      <Dialog
        visible={dialogTrabajador}
        style={{ width: "450px" }}
        header="Agregar empleado"
        footer={DialogTrabajadorFooter}
        modal
        className="p-fluid"
        onHide={hideDialogTrabajador}
      >
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            id="nombre"
            onChange={(e) => onInputChange(e, "nombre")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submittedAlta && !product.nombre,
            })}
          />
          {submittedAlta && !product.nombre && (
            <small className="p-error">Nombre Requerido</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="apellido">Apellido</label>
          <InputText
            id="apellido"
            onChange={(e) => onInputChange(e, "apellido")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submittedAlta && !product.apellido,
            })}
          />
          {submittedAlta && !product.apellido && (
            <small className="p-error">Apellido requerido</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="dni">DNI</label>
          <InputText
            id="dni"
            onChange={handleDniChange}
            keyfilter="num"
            required
            autoFocus
            maxLength={8}
            className={classNames({ "p-invalid": submittedAlta && !product.dni })}
          />
          {showErrorDni && <span style={{ color: "red" }}>El DNI debe tener al menos 7 números</span>}
          {submittedAlta && !product.dni && (
            <small className="p-error">Dni requerido</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="cuil">CUIL</label>
          <InputText
            id="cuil"
            onChange={handleCuilChange}
            keyfilter="num"
            required
            autoFocus
            maxLength={11}
            className={classNames({ "p-invalid": submittedAlta && !product.cuil })}
          />
          {showErrorCuil && <span style={{ color: "red" }}>El CUIL debe tener al menos 10 números</span>}
          {submittedAlta && !product.cuil && (
            <small className="p-error">CUIL requerido</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="ingresos_bruto">Sueldo Bruto</label>
          <InputText
            id="ingresos_bruto"
            onChange={(e) => onInputChange(e, "ingresos_bruto")}
            keyfilter="num"
            required
            autoFocus
            className={classNames({
              "p-invalid": submittedAlta && !product.ingresos_bruto,
            })}
          />
          {submittedAlta && !product.ingresos_bruto && (
            <small className="p-error">Ingresos Requeridos</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="email">Email</label>
          <InputText
            id="email"
            onChange={(e) => onInputChange(e, "email")}
            required
            autoFocus
          />
          <small >Campo opcional</small>

        </div>

        <div className="field">
          <label htmlFor="dni">Teléfono</label>
          <InputText
            id="telefono"
            onChange={(e) => onInputChange(e, "telefono")}
            keyfilter="num"
            required
            autoFocus />
          <small >Campo opcional</small>
        </div>

        <div className="field">
          <label htmlFor="nombre">Domicilio</label>
          <InputText
            id="domicilio"
            onChange={(e) => onInputChange(e, "domicilio")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !product.domicilio,
            })}
          />
          {submittedAlta && !product.domicilio && (
            <small className="p-error">Domicilio Requerido</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="genero">Genero</label>
          <Dropdown
            id="genero"
            value={product.genero}
            onChange={(e) => handlerGenero(e.value)}
            options={["Masculino", "Femenino", "No binario"]}
            placeholder="Genero"
            className="w-full md:w-14rem"
          />

          {submittedAlta && !product.genero && (
            <small className="p-error">genero requerido</small>
          )}
        </div>
        <div>
          <div>
            <label htmlFor="fechainicio">Fecha de ingreso</label>
          </div>
          <input
            id="fechainicio"
            type="date"
            value={fecha}
            onChange={(e) => {
              const selectedDate = new Date(e.target.value + "T00:00:00");
              const currentDate = new Date();
              if (selectedDate > currentDate) {
                e.target.value = "";
                Swal.fire({
                  icon: 'error',
                  title: 'Selección incorrecta',
                  text: 'No se puede seleccionar una fecha futura',
                });
              } else {
                setFecha(e.target.value);
                onInputChange(e, "inicio_actividad");
              }
            }}
          />
          {submittedAlta && !product.inicio_actividad && (
            <small className="p-error">Fecha requerida</small>
          )}
        </div>

        <h6 id="msjNuevoTrabajador">
          {" "}
          *Todos los datos requeridos son obligatorios
        </h6>
      </Dialog>


    </>
  );
};

export default TablaTrabajadores;