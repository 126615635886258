import axios from "axios";
import { accessToken } from "../components/utils/LocalStorage";
import moment from 'moment'
const env = process.env.REACT_APP_API_URL
export class ProductService {

    constructor(empresaId = null) {
        this.empresaId = empresaId;

    }
    getProducts(empresaIdUrl = null) {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/getemployees`, { params: { empresaId: empresaIdUrl === null ? this.empresaId : empresaIdUrl } })
            .then(d => d.data);
    }

    async getDeclaracionesJuradas(empresaId = null) {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/getddjj`, { params: { empresaId: empresaId === null ? this.empresaId : empresaId } })
            .then(d => d.data);
    }

    getEmpresas() {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/getBusiness`)
            .then(d => d.data);
    }

    getEmpresaById() {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/getempresabyid`, { params: { empresaId: this.empresaId } })
            .then(d => d.data);

    }

    getDeclaracionJuradaByMesTributo(mesTributo) {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/validateDDJJ`, { params: { empresaId: this.empresaId, mes: mesTributo } })
            .then(d => d.data);
    }
    getDeclaracionJuradaById(id) {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/getinfoddjj`, { params: { empresaId: this.empresaId, idDeclaracion: id } })
            .then(d => d.data);
    }

    getAllUsers() {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/getAllUser`)
            .then(d => d.data);
    }
    deleteUser(id) {
        this.setUltimaActividad();
        const body = {
            id: id,
            empresaId: this.empresaId
        }
        return axios
            .put(`${env}/api/deleteuser`, body)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                return error
            })
    }

    deleteInteresCargado(id) {
        this.setUltimaActividad();
        const body = {
            id: id
        }

        return axios
            .put(`${env}/api/eliminarTasa`, body)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                return error
            })
    }

    validateSession() {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/session`, {
                'headers': {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .then(({ data }) => { })
            .catch(({ response }) => { });
    }
    async createUser(body) {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/createuser`, body)
            .then(({ data }) => { })
            .catch(({ response }) => { });
    }


    async createUserAndEmpresa(body) {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/createbusinesanduser`, body)
            .then((d) => { return d.data });
    }

    async getDdjjByStatus(empresaIdSelected, pago) {
        //SI LE MANDAMOS 0 EN EMPRESAID SELECTED VA A TRAER TODAS LAS PAGAS O IMPAGAS DE TODAS LAS EMPRESAS
        // PAGO == 0 IMPAGO
        // PAGO == 1 PAGO

        this.setUltimaActividad();
        return axios
            .get(`${env}/api/getDdjjByStatus`, { params: { empresaId: empresaIdSelected, pago: pago } })
            .then(d => d.data)
            .catch(({ response }) => { console.log(response) });
    }

    async sendFile(file, idDeclaracion) {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/files`, file, { params: { idDeclaracion: idDeclaracion } })
            .then(d => d.data)
            .catch(({ response }) => { });
    }
    async getEmpleadosPorSusDatos(body) {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/getEmployeesByDataPeople`, body)
            .then(({ data }) => { })
            .catch(({ response }) => { console.log(response) });
    }
    async setIntereses(body) {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/createintereses`, body)
            .then(({ data }) => { })
            .catch(({ response }) => { console.log(response) });
    }
    async getActivoCambioPassword(id) {
        return axios
            .get(`${env}/api/getActiveChangePassword`, { params: { idChange: id } })
            .then(d => d.data);
    }
    async getUserById(id) {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/getUserById`, { params: { userId: id, empresaId: this.empresaId } })
            .then(d => d.data);
    }
    async getMontosDdjjByIdDeclaracion(idDeclaracion) {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/getMontosDdjjByIdDeclaracion`, { params: { idDeclaracion: idDeclaracion } })
            .then(d => d.data);
    }
    async importEmployee(body) {
        this.setUltimaActividad();
        body.empresaId = this.empresaId
        return axios
            .post(`${env}/api/importEmployee`, body)
            .then((d) => {
                return d.data
            });
    }
    async importBusiness(body) {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/importBusiness`, body)
            .then((d) => { return d.data });
    }
    async updatePassword(body) {
        this.setUltimaActividad();
        return axios
            .put(`${env}/api/user`, body)
            .then(({ data }) => { return data })
            .catch(({ response }) => { });
    }
    async createDDJJ(body) {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/createDDJJ`, body)
            .then((({ data }) => { return data.resultado.insertId }))
            .catch(({ response }) => { });
    }
    async sendEmail(body) {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/sendemail`, body)
            .then((data) => { console.log(data); return data })
            .catch((response) => { console.log(response); return response });
    }
    async agregarEmpleado(body) {
        this.setUltimaActividad();
        body.empresaId = this.empresaId
        return axios
            .post(`${env}/api/createemployee`, body)
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => {
                return response
            })
    }

    async editarEmpleado(body) {
        this.setUltimaActividad();
        return axios
            .put(`${env}/api/employee`, body)
            .then(({ data }) => {
                return data
            })
            .catch(error => {

                return error
            })
    }

    async editarEmpresa(body) {
        this.setUltimaActividad();
        return axios
            .put(`${env}/api/business`, body)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                return error
            })
    }

    async payDDJJ(body) {
        this.setUltimaActividad();
        return axios
            .put(`${env}/api/payDDJJ`, body)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                return error
            })
    }
    async payWithMercadoPago(body) {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/payWithMercadoPago`, body)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                return error
            })
    }

    async payWithPagos360(body) {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/payWithPagos360`, body)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                return error
            })
    }

    updateUserAndBusiness(body) {
        this.setUltimaActividad();
        return axios
            .put(`${env}/api/updatebusinessanduser`, body)
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => {
                return response
            });
    }
    setUltimaActividad() {
        localStorage.setItem("ultimaActividad", JSON.stringify({ horaUltimaActividad: moment().add(1, 'hours').format() }))
    }
    async payWithPagos360(body) {
        this.setUltimaActividad();
        return axios
            .post(`${env}/api/payWithPagos360`, body)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                return error
            })
    }
    async getDateIntereses() {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/interesesmensuales`)
            .then((d) => { return d.data });

    }

    async getInteresesForTable() {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/interesesmensualestabla`)
            .then((d) => { return d.data });

    }

    getVersion() {
        this.setUltimaActividad();
        return axios
            .get(`${env}/api/version`)
            .then(d => d.data);
    }



    async updateLinkPaymentAndId(body) {
        this.setUltimaActividad();
        try {
            const response = await axios.put(`${env}/api/updateLinkAndId`, body);
            return response.data;
        } catch (error) {
            console.error('Error al actualizar el link y ID de pago:', error);

            // Manejar el error de la forma que prefieras, por ejemplo, lanzándolo de nuevo
            throw new Error(error.response?.data?.message || 'Error al actualizar el link y ID de pago');
        }
    }

    async actualizoDatosPagosDdjj(body) {
        this.setUltimaActividad();
        return axios
            .put(`${env}/api/actualizoDatosTresSeisCero`, body)
            .then(d => d.data);
    }
}
