/* FEXPORTADORES DE EXCEL Y PDF */
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import Alerta from "../components/utils/AlertExport";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo from "../components/utils/encabezado.png"
import pie from "../components/utils/pie.png"


import Swal from 'sweetalert2';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


/* FUNCION QUE EXPORTA EMPRESAS SELECCIONADOS A EXCEL */
export function exportExcel(selectedCustomers) {
  if (selectedCustomers == null || selectedCustomers.length == 0) {
    Swal.fire({
      icon: 'error',
      title: `SELECCION INCOMPLETA`,
      text: ` Debe seleccionar las filas `,
    })
  } else {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Datos seleccionados');

    // Establecer encabezados de columna
    const headers = [
      'CUIT',
      'Razón Social',
      'Rama',
      'Direccion',
      'Localidad',
      'Departamento',
      'Provincia',
      'Codigo postal',
      'Teléfono',
      'E-Mail',
      'Inicio AFIP',
      'Inicio Empleador',
    ];
    worksheet.addRow(headers);

    // Agregar filas de datos
    selectedCustomers.forEach((customer) => {
      const rowData = [
        customer.cuit,
        customer.razon_social,
        customer.rama,
        customer.direccion,
        customer.localidad,
        customer.departamento,
        customer.provincia,
        customer.cod_postal,
        customer.telefono,
        customer.email,
        customer.inicio_afip,
        customer.inicio_activ_empleador,
      ];
      worksheet.addRow(rowData);
    });

    // Establecer estilo para los encabezados de columna
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0080ff' },
      };
    });


    // Ajustar el ancho de las columnas automáticamente
    worksheet.columns.forEach((column) => {
      column.width = 15;
    });

    // Generar el archivo Excel
    workbook.xlsx.writeBuffer().then((buffer) => {
      const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(data, 'ListadoEmpresas.xlsx');
    });
  }

};

/* FUNCION QUE EXPORTA EMPRESAS SELECCIONADOS A PDF */

 export async function exportPdf (selectedCustomers)  {
  if (selectedCustomers == null || selectedCustomers.length == 0) {
    Alerta()
    return;
  } else {
    try {
      // Cargar la imagen del logo
      const logoResponse = await fetch(logo);
      const logoBlob = await logoResponse.blob();
      const base64Logo = await convertirImagenABase64Async(logoBlob);

      // Cargar la imagen de pie
      const pieResponse = await fetch(pie);
      const pieBlob = await pieResponse.blob();
      const base64Pie = await convertirImagenABase64Async(pieBlob);


          const cellStyle = {
            fontSize: 8, // Tamaño de la letra para el contenido de la tabla
          };        

          const selectedCustomersUpper = selectedCustomers.map((customer) => ({
            cuit: customer.cuit.toUpperCase(),
            razon_social: customer.razon_social.toUpperCase(),
            rama: customer.rama.toUpperCase(),
            direccion: customer.direccion.toUpperCase(),
            localidad: customer.localidad.toUpperCase(),
            departamento: customer.departamento.toUpperCase(),
            provincia: customer.provincia.toUpperCase(),
            cod_postal: customer.cod_postal.toUpperCase(),
            telefono: customer.telefono.toUpperCase(),
            email: customer.email.toUpperCase(),
            inicio_afip: customer.inicio_afip,
            inicio_activ_empleador: customer.inicio_activ_empleador,
          }));

          const table = {
            headerRows: 1,
            widths: [60, 60, 50, 60, 50, 65, 50, 35, 40, 50, 50, 55], // Ajusta los valores según tus necesidades
            body: [
              // Encabezados de columna
              [
                { text: 'CUIT', style: 'header' },
                { text: 'RAZÓN SOCIAL', style: 'header' },
                { text: 'RAMA', style: 'header' },
                { text: 'DIRECCIÓN', style: 'header' },
                { text: 'LOCALIDAD', style: 'header' },
                { text: 'DEPARTAMENTO', style: 'header' },
                { text: 'PROVINCIA', style: 'header' },
                { text: 'CP', style: 'header' },
                { text: 'TELÉFONO', style: 'header' },
                { text: 'E-MAIL', style: 'header' },
                { text: 'INICIO AFIP', style: 'header' },
                { text: 'INICIO EMPLEADOR', style: 'header' }
              ],
              // Filas de datos
              ...selectedCustomersUpper.map((customer) => [
                { text: customer.cuit, style: cellStyle },
                { text: customer.razon_social, style: cellStyle },
                { text: customer.rama, style: cellStyle },
                { text: customer.direccion, style: cellStyle },
                { text: customer.localidad, style: cellStyle },
                { text: customer.departamento, style: cellStyle },
                { text: customer.provincia, style: cellStyle },
                { text: customer.cod_postal, style: cellStyle },
                { text: customer.telefono, style: cellStyle },
                { text: customer.email, style: cellStyle },
                { text: customer.inicio_afip, style: cellStyle },
                { text: customer.inicio_activ_empleador, style: cellStyle },
              ]),
            ],
            styles: {
              header: {
                fillColor: '#0080ff',
                color: '#ffffff',
                bold: true,
              },
            },
          };
          table.body[0].forEach(header => {
            header.fontSize = 8;
          });
          const docDefinition = {
            pageSize: 'A3',
            header: {
              image: base64Logo,
              width: 550,
              alignment: 'center'
            },
            content: [             
              { table: table, layout: 'lightHorizontalLines', alignment: 'center', margin: [0, 120, 0, 0] },
            ],
            footer: {
              image: base64Pie,
              width: 650,
              alignment: 'center'
            },
            pageMargins: [15, 0, 0, 30], // Incrementamos el margen inferior para dar espacio al pie de página
          };

          // Generar el archivo PDF
          const pdfDocGenerator = pdfMake.createPdf(docDefinition);
          pdfDocGenerator.getBlob((blob) => {
            saveAs(blob, 'ListadoEmpresas.pdf');
          });
        } catch (error) {
          console.error('Error al exportar PDF:', error);
          // Manejar el error según sea necesario
        }
      }
};

// Función asincrónica para convertir una imagen a base64
const convertirImagenABase64Async = async (imagen) => {
  return new Promise((resolve, reject) => {
    const lector = new FileReader();
    lector.readAsDataURL(imagen);
    lector.onload = () => {
      resolve(lector.result);
    };
    lector.onerror = (error) => {
      reject(error);
    };
  });
};

/* FUNCION QUE EXPORTA MODELO DE ARCHIVO EXCEL PARA LUEGO IMPORTAR LOS EMPLEADOS */

export function exportColumnNamesToExcel() { 
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Column Names");

  worksheet.addRow([
    "cuil",
    "dni",
    "apellido",
    "nombre",
    "genero",
    "ingresos_bruto",
    "inicio_actividad",
    "domicilio",
    "telefono",
    "email",
    "activo",
  ]);

  workbook.xlsx.writeBuffer().then((buffer) => {
    const data = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    const filename = "modeloTablaTrabajadores.xlsx";
    saveAs(data, filename);
  });
}

