import * as React from "react";
import { useRef } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import SignUp from "../signUp/SignUp";
import "./login.css";
import maderas from "./maderas.jpeg";
import ReCAPTCHA from "react-google-recaptcha";
import Paper from "@mui/material/Paper";
import moment from 'moment';
import logoSoimp from "./logoSOIMP.png";

const SignIn = () => {
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dataSession, setDataSession] = useState({});
  const { setSessionInLocalStorage, setLoggin } = useContext(UserContext);
  const [body, setBody] = useState({ username: "", password: "" });
  const navigate = useNavigate();
  const location = useLocation();
  const captchaRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const from = location.state?.from?.pathname || "/";
  const env = process.env.REACT_APP_API_URL;
  const [cuitError, setCuitError] = useState(false);
  const [passError, setPassError] = useState(false);

  const inputChange = ({ target }) => {
    const { name, value } = target;

    setBody({
      ...body,
      [name]: value,
    });
  };

  const errors = {
    uname: "Cuit invalido",
    pass: "Contraseña invalida",
    captcha: "Valide el captcha",
  };

  function encryptAES(text, key) {
    return CryptoJS.AES.encrypt(text, key).toString();
  }

  const onClickIniciarSesion = () => {
    body.password = encryptAES(body.password, "pass");
    const token = captchaRef.current.getValue();
    if (token !== "") {
      body.token = token;
      captchaRef.current.reset();
      axios
        .post(`${env}/api/auth`, body)
        .then(({ data }) => {
          const { error } = data;
          console.log("soy el error", error);
          setIsSubmitted(true);
          if (error === undefined) {
            setSessionInLocalStorage({
              dateLoggin: data.dateLoggin,
              idSession: data.idSession,
              empresaId: data.empresaId,
              empresa: data.empresa,
              admin: data.admin,
              nombre: data.nombre,
              userId: data.id,
              accessToken: data.accessToken
            });           
            setLoggin(true);
            if (data.admin === 1) {
              navigate("/empresas", { replace: true });
            } else {
              navigate("/empleados", { replace: true });
            }
            localStorage.setItem("ultimaActividad", JSON.stringify({ horaUltimaActividad: moment().add(1, 'hours').format() }))
          }
          if (error === 800) {
            setErrorMessages({ name: "pass", message: errors.pass });
            setPassError(true);
          }
          if (error === 700) {
            setErrorMessages({ name: "uname", message: errors.uname });
          }
        });
    }
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  function Copyright(props) {
    return (
      <>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          {...props}
        >
          {"Copyright © "}
          {new Date().getFullYear()}
          <a href="http://www.itdbservices.com/">ITDB Services</a>
          <br />
          Powered by ITDB Services
          {"."}
        </Typography>
      </>
    );
  }

  const LogosSindicato = () => {
    return (
      <>
        <Box id="logosContainerLogin">
          <div id="individualContainerLogin">
            <img src={logoSoimp} alt="logo-soimp" id="logoSoimp" />       
          </div>       
        </Box>
      </>
    );
  };

  const theme = createTheme();

  const handleInputChange = (event) => {
    const { value } = event.target;
    const cleanValue = value.replace(/\D/g, ''); // Elimina cualquier carácter que no sea un dígito
    setBody((prevState) => ({
      ...prevState,
      username: cleanValue.slice(0, 11) // Limita la longitud a 11 caracteres
    }));
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />

          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: "url(" + maderas + ")",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Sistema de gestión de DDJJ
              </Typography>
              <Box
                component="form"                
                sx={{ mt: 1 }}                
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="cuit"
                  type="text" // Cambiado a "text" para control total sobre la entrada
                  label="Ingrese su cuit"
                  name="username"
                  autoComplete="cuit"
                  autoFocus
                  value={body.username}
                  onChange={handleInputChange}
                  error={cuitError}
                  inputProps={{ maxLength: 11 }}
                />
                {renderErrorMessage("uname")}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Ingrese su contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={inputChange}
                  inputProps={{ maxLength: 25 }}
                  error={passError}
                />
                {renderErrorMessage("pass")}
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                  id="captcha"
                />
                {renderErrorMessage("captcha")}

                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={onClickIniciarSesion}
                >
                  INGRESAR
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link to={"/recuperar-contrasena"}>Recuperar clave</Link>
                  </Grid>
                  <Grid item>
                    <Link to={"/alta-empresa"} component={<SignUp />}>
                      Alta empresa
                    </Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
            <LogosSindicato />
          </Grid>
        </Grid>  
      </ThemeProvider>
    </>
  );
};

export default SignIn;
