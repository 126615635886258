import './App.css';
import SignIn from './components/login/Login';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useState } from 'react';
import { useEffect } from 'react';
import { UserProvider, UserContext } from './context/UserContext';
import RouterLogged from './routes/RouterLogged';
import SignUp from './components/signUp/SignUp';
import ResetPw from './components/ResetPw/ResetPw';
import ChangePws from './components/ResetPw/ChangePws';
import Footer from './footer/Footer';

function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const sessionActual = JSON.parse(localStorage.getItem("session")) || undefined;
    setSession(sessionActual);
  }, [])


  return (
    <>
      <div className='containerGeneral'>
        <div className='contentUno'>
          <UserProvider>
            <BrowserRouter>
              {session === undefined ? (
                <Routes>
                  <Route path="/auth" element={<SignIn />} />
                  <Route path="/alta-empresa" element={<SignUp />} />
                  <Route path="/recuperar-contrasena" element={<ResetPw />} />
                  <Route path="/reset-password/:id" element={<ChangePws />} />
                  <Route path="/" element={<Navigate to="/auth" replace={true} />} />
                  <Route path="/empleados" element={<Navigate to="/auth" replace={true} />} />
                  <Route path="/ddjj" element={<Navigate to="/auth" replace={true} />} />
                  <Route path="/perfil" element={<Navigate to="/auth" replace={true} />} />
                  <Route path="/abmemp" element={<Navigate to="/auth" replace={true} />} />
                  <Route path="/empresas" element={<Navigate to="/auth" replace={true} />} />
                  <Route path="/nuevaDDJJ" element={<Navigate to="/auth" replace={true} />} />
                </Routes>
              ) : (
                <RouterLogged />
              )}
            </BrowserRouter>
          </UserProvider>
        </div>
        {session !== undefined && <Footer />} 
      </div>
    </>
  );
}

export default App;