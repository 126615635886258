import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { empresaId } from "../utils/LocalStorage";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link } from "react-router-dom";
import { useEffect,useState } from "react";
import { ProductService } from "../../service/ProductService";

const PaySuccessful = () => {
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  const collection_id = urlParams.get("collection_id") || null;
  const idDeclaracion = urlParams.get("idDeclaracion") || null;
  const productService = new ProductService(empresaId);
  const [idPago,setIdPago] = useState();
  useEffect(() => {
    if (idDeclaracion != null) {

      productService.getDeclaracionJuradaById(idDeclaracion).then((actual) => {
        setIdPago(actual.id_de_pago);
        const body = {
          metodoPago: "pagos 360 | Transacción:" + actual.id_de_pago,
          idDeclaracion: idDeclaracion,
        };
        productService.payDDJJ(body);
      })
      
    }
  }, []);

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            minWidth: "500px",
            minHeight: "300px",
            border: "1px solid #F5F5F5",
            boxShadow:
              "-10px 2px 13px 2px #00000033, 10px 2px 13px 2px #00000033, -50px 22px 15px -30px rgba(0,0,0,0);",
            backgroundColor: "#F5F5F5",
            borderRadius: "13px",
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            sx={{ maxWidth: 600, minHeight: 300, minWidth: 200, width: 600 }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#006699",
                minHeight: 190,
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <CheckCircleOutlineIcon
                sx={{
                  color: "white",
                  fontSize: 70,
                  marginBottom: "15px",
                }}
              />
              <Typography
                textAlign="center"
                gutterBottom
                variant="h5"
                component="div"
                color="white"
              >
                Su pago ha sido realizado correctamente.
              </Typography>
            </Grid>
            <CardContent>
              <Typography variant="h8" color="black" textTransform="uppercase">
                Nº de Operacion: {idPago}
              </Typography>
            </CardContent>

            <CardActions>
              <Link to={"/"}>
                <Button size="small" color="primary">
                  VOLVER AL INICIO
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Box>
      </Container>
    </div>
  );
};

export default PaySuccessful;
