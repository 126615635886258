import axios from 'axios';
const env = process.env.REACT_APP_API_URL
export class CustomerService {

        getCustomersLarge() {
                return axios
                        .get(`${env}/api/allbusiness`, { params: { empresaId: 1 } })
                        .then(d => d.data);
        }
}
