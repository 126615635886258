import { Dropdown } from "primereact/dropdown";
import { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { ProductService } from "../../service/ProductService";
import { empresaId } from "../utils/LocalStorage";
import Swal from 'sweetalert2'
import { Card } from "primereact/card";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";


const NewDDJJ = ({ children }) => {
  const [ddjjInfo, setddjjInfo] = useState();
  const [checked, setChecked] = useState(false);
  const [mes, setMes] = useState("");
  const [anio, setAnio] = useState("");
  const [ddjj, setDDJJ] = useState("");
  const [objetoddjj, setObjetoDdjj] = useState();
  const [urlMeli, setUrlMeli] = useState();
  const [paymentNow, setPayNow] = useState(false);
  const productService = new ProductService(empresaId);
  const [selectedDdjj, setSelectedDdjj] = useState(null);
  const [muestroContenedor, setmuestroContenedor] = useState(true)
  let ddjjId = 0
  useEffect(() => {
    setObjetoDdjj(children);
  }, []);

  useEffect(() => {
    productService.getDeclaracionesJuradas().then((data) => {
      const uniqueData = data.reduce((accumulator, current) => {
        if (!accumulator[current?.mestributo] || accumulator[current?.mestributo].iddeclaracion < current?.iddeclaracion) {
          accumulator[current?.mestributo] = current;
        }
        return accumulator;
      }, {});

      const sortedData = Object.values(uniqueData).sort((a, b) => a?.mestributo.localeCompare(b?.mestributo));
      setddjjInfo(sortedData);
    });
  }, [checked]);

  const meses = [
    { label: "Enero", value: "01" },
    { label: "Febrero", value: "02" },
    { label: "Marzo", value: "03" },
    { label: "Abril", value: "04" },
    { label: "Mayo", value: "05" },
    { label: "Junio", value: "06" },
    { label: "Julio", value: "07" },
    { label: "Agosto", value: "08" },
    { label: "Septiembre", value: "09" },
    { label: "Octubre", value: "10" },
    { label: "Noviembre", value: "11" },
    { label: "Diciembre", value: "12" },
  ];

  const anios = [
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
    { label: "2026", value: "2026" },
    { label: "2027", value: "2027" },
    { label: "2028", value: "2028" },
    { label: "2029", value: "2029" },
    { label: "2030", value: "2030" },
    { label: "2031", value: "2031" },
    { label: "2032", value: "2032" },
    { label: "2033", value: "2033" },
  ];



  const CuponPago = () => {
    const footer = (
      <span>
        <div className="btnPagos">
          <Link to="/ddjj" style={{ textDecoration: "none" }}>
            <Button
              label="Ir a mis DDJJ"
              icon="pi pi-clock"
              className="p-button-rounded p-button-warning"
              id="btnAB"
            />
          </Link>
        </div>
      </span>
    );

    return (
      <div className="cardDDJJ">
        <Card
          title="GENERACIÓN DE DDJJ EXITOSA"
          style={{ width: "28em" }}
          footer={footer}
        >
          <h5>
            {" "}
            <strong>Periodo:</strong> {mes === "" || anio === "" ? selectedDdjj?.mestributo : objetoddjj.mesTributo}
          </h5>
          <h5> <strong>Total de remuneraciones brutas:</strong> {objetoddjj.sumaSueldos.toLocaleString("es-AR", { style: "currency", currency: "ARS" })}</h5>
          <h5> <strong> Tipo:</strong> {checked ? "Rectificada" : "Original"}</h5>
          <h4> <strong>Total a tributar:</strong> {objetoddjj.monto.toLocaleString("es-AR", { style: "currency", currency: "ARS" })}</h4>
          <h6><strong>*Tenga en cuenta que si la presente DDJJ no fue generada en término, se aplicarán intereses</strong></h6>
        </Card>
      </div>
    );
  };

  const handleClick = () => {
    if (mes === "" || anio === "") {    
      Swal.fire({
        icon: 'error',
        title: `DEBE SELECCIONAR MES Y AÑO DE LA DDJJ A PRESENTAR`,       
        customClass: {
          popup: 'custom-modal-class',
        },
      });
    } else {
      const fechaSeleccionada = new Date(`${anio}-${mes}-01`);
      
  
      const fechaActual = new Date();
      const primerDiaMesActual = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
      const primerDiaMesAnterior = new Date(fechaActual.getFullYear(), fechaActual.getMonth() - 1, 1);
      
  
      if (fechaSeleccionada >= primerDiaMesActual || fechaSeleccionada >= primerDiaMesAnterior) {
        Swal.fire({
          icon: 'error',
          title: `PERIODO INCORRECTO  <br/>${mes}/${anio}`,
          text: `No se puede generar una DDJJ del mes en curso ni de meses futuros`,
          customClass: {
            popup: 'custom-modal-class',
          },
        });
        setMes("");
        setAnio("");
        return;
      } else {
        objetoddjj.mesTributo = mes + "/" + anio;
        productService.getDeclaracionJuradaByMesTributo(objetoddjj.mesTributo).then((value) => {
          if (value.length == 0) {
            setDDJJ(<CuponPago />);
            saveDdjj();
            setmuestroContenedor(false);
            document.getElementById('btnCancelarDDJJ').remove();
          } else {
            Swal.fire({
              title: 'El periodo ingresado ya se encuentra registrado',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
              customClass: {
                popup: 'custom-modal-class',
              },
            });
          }
  
          if (checked) {
            setDDJJ(<CuponPago />);
            objetoddjj.rectificacionId = selectedDdjj.iddeclaracion;
  
            saveDdjj();
          }
        });
      }
    }
  };  

  const rectifyClick = () => {
    if (selectedDdjj == null) {     
      Swal.fire({
        icon: 'error',
        title: `DEBE SELECCIONAR LA DDJJ QUE DESEA RECTIFICAR`,       
        customClass: {
          popup: 'custom-modal-class',
        },
      });
    } else {
      document.getElementById("contenedor-rectificacion").style.display = "none";
      document.getElementById("contenedorCheck").style.display = "none";

      objetoddjj.mesTributo = selectedDdjj.mestributo;
      productService.getDeclaracionJuradaByMesTributo(objetoddjj.mesTributo).then((value) => {
        if (value.length == 0) {
      
          setDDJJ(<CuponPago />);
          saveDdjj();
          document.getElementById("contenedor-rectificacion").style.display = "none";
        } else {
        }
        if (checked) {
          setDDJJ(<CuponPago />);
          objetoddjj.rectificacionId = selectedDdjj.iddeclaracion;
          saveDdjj();
          document.getElementById('btnCancelarDDJJ').remove();
          document.getElementById("contenedor-rectificacion").style.display = "none";
        }

      })


    }
  };

  const saveDdjj = async (payNow = false) => {
   
    await saveProduct(objetoddjj);
  };
  const payPopUpNow = async () => {
    await createLinkPayment().then(() => {
      setPayNow(true);
    });
  };
  const createLinkPayment = async () => {
    debugger;
    const body = {
      item: {
        title: `Pago DDJJ del mes ${mes + "/" + anio}`,
        unit_price: objetoddjj.monto,
        quantity: 1,
      },
      empresaId: empresaId,
      idDeclaracion: ddjjId
    };
    productService.payWithMercadoPago(body).then((value) => {
      const { init_point, sandbox_init_point } = value;
      setUrlMeli(sandbox_init_point);
    });
  };
  const saveProduct = async (ddjj) => {
    await productService.createDDJJ(ddjj).then((value) => {
      ddjjId = value
    });
  };
  const hideDialogPagarAhora = () => {
    setPayNow(false);
  };


  return (
    <>
      <div className="contenedorDec" style={{ position: "relative", zIndex: 9999 }}>
        <div>

          {muestroContenedor ?
            <div id="contenedor-rectificacion" style={{ display: "flex", flexDirection: "column"}}>


              <div

                className="card flex"
                style={{
                  flexDirection: "column",
                  minHeight: "300px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",                  
                  background:"#555"
                }}
              >
                <div id="infoTitle">
                  
                  <p>Aquí podrá <strong>GENERAR</strong> una nueva declaracion jurada o <strong>RECTIFICAR</strong> una existente.</p>
                  <p>En ambos casos se realizarán <strong>a partir de los empelados ya seleccionados.</strong></p>
                  <Divider />
                </div>
                <div style={{display:"flex"}}>
                {/* INICIO CONTENEDOR GENERAR NUEVA DDJJ */}
                <div className="ddjjOriginalContainer">

                  {checked ? "" : <>
                    <div>
                      <h4>GENERAR NUEVA DDJJ</h4>
                      <div id="opcionesddjj">
                        <Dropdown
                          value={mes}
                          options={meses}
                          onChange={(e) => setMes(e.value)}
                          placeholder="Elija el mes"
                          id="ddjjDrop"
                        />
                        <Dropdown
                          value={anio}
                          options={anios}
                          onChange={(e) => setAnio(e.value)}
                          placeholder="Elija el año"
                          id="ddjjDrop"
                        />
                      </div>

                      <div id="btnsGenerarClonar" style={{
                        justifyContent: " center",
                        display: "flex",
                        marginTop: "10px",
                      }}>

                        <Button className="p-button-success" label="CREAR DDJJ" onClick={handleClick} id="btnDDJJ" />
                      </div>
                    </div>
                    <div>
                      <Divider layout="vertical" style={{ minHeight: "100px" }} id="divider" />
                    </div>
                  </>
                  }
                </div>

                {/* FIN CONTENEDOR GENERAR NUEVA DDJJ */}

                {/* INICIO CONTENEDOR RECTIFICAR DDJJ */}
                <div style={{ marginLeft: "5px", marginRight: "50px" }} id="rectContainer">
                  <div className="flex flex-column gap-2">

                    <h4>RECTIFICAR DDJJ EXISTENTE</h4>

                    <div className="field-checkbox" id="contenedorCheck">
                      <label htmlFor="binary" className="checkContainer"> Tilde el casillero si desea RECTIFICAR una DDJJ existente</label>
                      <Checkbox
                        inputId="binary"
                        checked={checked}
                        onChange={(e) => setChecked(e.checked)}
                        style={{ marginLeft: "8px", width: "25px" }}
                      />
                    </div>
                  </div>
                  {checked ? (
                    <div style={{ marginLeft: "5px", marginRight: "50px" }}>
                      <div className="flex flex-column gap-2">
                        <div className="dropdown-demo">
                          <Dropdown
                            value={selectedDdjj}
                            options={ddjjInfo}
                            onChange={(e) => setSelectedDdjj(e.value)}
                            optionLabel="mestributo"
                            placeholder="Mes tributo"
                          />
                          <Button style={{ marginLeft: "5px" }} className="p-button-success" label="GENERAR" onClick={rectifyClick} />
                        </div>
                      </div>
                    </div>

                  ) : (
                    ""
                  )}
                </div>
                {/* FIN CONTENEDOR RECTIFICAR DDJJ */}
                </div>
              </div>
            </div>
            : null}
        </div>

        <div className="decJur">
          {ddjj}
        </div>
        {/* COMIENZO OPCIONES DE PAGO */}
        <Dialog
          header="Pagar via.."
          visible={paymentNow}
          className="p-fluid"
          style={{ width: "50vw" }}
          modal
          onHide={hideDialogPagarAhora}
        >
          <div className="contenedorBotonesBilleteras">
            {/* <button icon="pi pi-check" className="p-button-rounded p-button-success" >Mercado Pago</button> */}
            <a
              href={urlMeli}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button
                label="Mercado Pago"
                icon="pi pi-clock"
                className="p-button-rounded p-button-warning"
                id="btnAB"
              />
            </a>
            {/* <button icon="pi pi-check" className="p-button-rounded p-button-success" >Pagos 360</button> */}
          </div>
        </Dialog>
        {/* FIN OPCIONES DE PAGO */}
      </div >
    </>
  );
};
export default NewDDJJ;