import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useState} from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProductService } from "../../service/ProductService";
import { Typography } from "@mui/material";
import { Dropdown } from 'primereact/dropdown';

const SignUp = () => {
  // USER
  const [cuit, setCuit] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [email, setEmail] = useState("");
  const [userNombre, setUserNombre] = useState("");
  const [userApellido, setUserApellido] = useState("");
  const [showError, setShowError] = useState(false)

  //empresa

  const [razonsocial, setRazonSocial] = useState("");
  const [empcuit, setEmpCuit] = useState("");
  const [empemail, setEmpEmail] = useState("");
  const [empdireccion, setEmpDireccion] = useState("");
  const [emplocalidad, setEmplocalidad] = useState("");
  const empprovincia = "Misiones";
  const [empcodpostal, setEmpcodpostal] = useState("");
  const [emptelefono, setEmptelefono] = useState("");
  const [empdepartamento, setEmpDepartamento] = useState("");
  const [empinicioafip, setEmpinicioafip] = useState("");
  const [empinicioactivempleador, setEmpinicioactivempleador] = useState("");
  const [emprama, setEmprama] = useState("");
  const service = new ProductService(0);
  //contador

  const [contnombre, setContNombre] = useState("");
  const [contdireccion, setContDireccion] = useState("");
  const [conttelefono, setContTelefono] = useState("");
  const [contapellido, setContApellido] = useState("");
  const [contaMail, setContaMail] = useState("");

  //socio

 /*  const [socnombre, setSocnombre] = useState("");
  const [socdomicilio, setSocdomicilio] = useState("");
  const [soccuit, setSoccuit] = useState("");
 */
  //personal

 /*  const [pernombre, setPernombre] = useState("");
  const [perapellido, setPerapellido] = useState("");
  const [peringresosbrutos, setPeringresosbrutos] = useState("");
  const [peremail, setPeremail] = useState("");
 */
  //pagos

 /*  const [pagplataforma, setPagplataforma] = useState("");
  const [pagfechapago, setPagfechapago] = useState("");
  const [pagmonto, setPagmonto] = useState("");
  const [existEmpresa, setExistEmpresa] = useState(false);
 */
  const body = {
    empresa: {
      razon_social: razonsocial,
      email: empemail,
      direccion: empdireccion,
      localidad: emplocalidad,
      departamento: empdepartamento,
      provincia: empprovincia,
      cod_postal: empcodpostal,
      telefono: emptelefono,
      inicio_afip: empinicioafip,
      inicio_activ_empleador: empinicioactivempleador,
      rama: emprama,
      cuit: empcuit,
    },
    user: {
      cuit: cuit,
      password: password,
      email: email,
      admin: false,
      empresaId: "",
      nombre: userNombre,
      apellido: userApellido,
    },
    contador: {
      nombre: contnombre,
      apellido: contapellido,
      direccion: contdireccion,
      telefono: conttelefono,
      mail: contaMail,
      empresaId: ""
    }
  };
  

  const ramas = [
    "Carpinteria",
    "Aserradero",
    "Terciado",
    "Aglomerado"
  ]

  
  const departamentosALocalidades = {
    "Apostoles": [
      "Apostoles",
      "Azara",
      "San Jose",
      "Tres Capones"
    ],
    "Cainguas": [
      "Aristobulo del Valle",
      "Campo Grande",
      "2 de Mayo"
    ],
    "Candelaria": [
      "Bonpland",
      "Candelaria",
      "Cerro Cora",
      "Loreto",
      "Martires",
      "Profundidad",
      "Santa Ana"
    ],
    "Capital": [
      "Fachinal",
      "Garupa",
      "Posadas"
    ],
    "Concepcion": [
      "Concepcion de la Sierra",
      "Santa Maria",
    ],
    "Libertador San Martin": [
      "Capiovi",
      "El Alcazar",
      "Garuhape",
      "Puerto Leoni",
      "Puerto Rico",
      "Ruiz de Montoya"
    ],
    "Leandro N. Alem": [
      "Almafuerte",
      "Arroyo del Medio",
      "Caa-Yari",
      "Cerro Azul",
      "Dos Arroyos",
      "Gobernador Lopez",
      "Leandro N. Alem",
      "Olegario Victor Andrade"
    ],
    "Obera": [
      "Campo Ramon",
      "Campo Viera",
      "Colonia Alberdi",
      "General Alvear",
      "Guarani",
      "Los Helechos",
      "Obera",
      "Panambi",
      "San Martin"
    ],
    "San Ignacio": [
      "Colonia Polana",
      "Corpus",
      "General Urquiza",
      "Gobernador Roca",
      "Hipolito Irigoyen",
      "Jardin America",
      "San Ignacio",
      "Santo Pipo"
    ],
    "San Javier": [
      "Florentino Ameghino",
      "Itacaruare",
      "Mojon Grande",
      "San javier"
    ],
    "Veinticinco de Mayo": [
      "Alba Posse",
      "Colonia Aurora",
      "25 de Mayo",
      "Colonia Alicia"
    ]
  };

  const DevuelvoLocalidades = () => {
    if (empdepartamento == "") {
      return (
        <>
          <Grid item xs={6}>
            <FormControl>
              <FormLabel>Localidad:</FormLabel>
              <Dropdown
                disabled
                placeholder="Debe primero elegir el Departamento" />
            </FormControl>
          </Grid>
        </>
      )
    } else {
      const localidades = departamentosALocalidades[empdepartamento];
      return (
        <>
          <Grid item xs={6}>
            <FormControl>
              <FormLabel>Localidad:</FormLabel>
              <Dropdown value={emplocalidad} onChange={(e) => setEmplocalidad(e.target.value)} options={localidades}
                placeholder="Seleccione la localidad" />
            </FormControl>
          </Grid>
        </>
      )
    }
  };



  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));




  const isFormValid = () => {
    return (
      /*  userNombre.trim() !== "" &&
       userApellido.trim() !== "" && */
      email.trim() !== "" &&
      password.trim() !== "" &&
      empcuit.trim() !== "" && empcuit.length >= 10 && // Validar que el CUIT tenga al menos 10 caracteres     
      razonsocial.trim() !== "" &&
      cuit.trim() !== "" &&
      empemail.trim() !== "" &&
      empdireccion.trim() !== "" &&
      empdepartamento.trim() !== "" &&
      empcodpostal.trim() !== "" &&
      emptelefono.trim() !== "" &&
      empinicioafip.trim() !== "" &&
      empinicioactivempleador.trim() !== "" &&
      emprama.trim() !== "" &&
      contnombre.trim() !== "" &&
      contapellido.trim() !== "" &&
      contdireccion.trim() !== "" &&
      conttelefono.trim() !== ""&&
      contaMail.trim() !== ""
    );
  };

  const BtnEnviar = () => {
    const handleClick = () => {
      if (password !== confirmPassword) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR EN LA CONFIRMACIÓN DE CONTRASEÑA',
          text: 'Las contraseñas ingresadas no coinciden',
        })
      } else {
        if (isFormValid()) {          
          service.createUserAndEmpresa(body).then((value) => {
            //ACA NOTIFICACION QUE EL USUARIO Y LA EMPRESA SE CREARON CORRECTAMENTE (LISTO)
            //Y UN REDIRECT AL LOGIN  (LISTO)
            if (value.value === 800) {
              Swal.fire({
                title: "Ya la empresa se encuentra en nuestra base de datos <br> Comuniquese con un administrador",
                icon: "error",
                showCancelButton: false,
                confirmButtonColor: "green",
                confirmButtonText: "Recuperar contraseña",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.replace("/recuperar-contrasena");
                }
              });
            } else {
              Swal.fire({
                title: "¡Registro exitoso!",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "green",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.replace("/auth");
                }
              });
            }
          });
        }
      }
    };

    return (
      <Button
        variant="contained"
        color="primary"
        disabled={!isFormValid()}
        onClick={handleClick}
      >
        Enviar
      </Button>
    );
  };


  const handleEmpCuitChange = (e) => {
    const formattedCuit = e.target.value.replace(/\D/g, "").slice(0, 11); // Elimina cualquier caracter no numérico y limita a 11 caracteres
    setEmpCuit(formattedCuit);
    setCuit(formattedCuit)
    setShowError(formattedCuit.length < 10);
  };


  const handleMail = (e) => {
    setEmail(e.target.value);
    setEmpEmail(e.target.value)
  };

  const formulario = (
    <>
      <Card sx={{ maxWidth: "900px", paddingTop: "10px", marginTop: "20px", fontFamily: "Verdana", boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" }}>
        <CardContent>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h5" component="div">
              Formulario de Alta Empresa
            </Typography>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              xs={9}
              container
              spacing={2}
              sx={{
                maxWidth: "",
              }}
            >
              <Grid item xs={12}>
                <Item style={{ backgroundColor: "#1976d2", color: "white", fontFamily: "Verdana" }}>Ingrese los datos de usuario</Item>
                <h6>*Todos los datos son obligatorios</h6>
              </Grid>
              {/* 
              <Grid item xs={6}>
              <FormControl>
              <FormLabel>NOMBRE:</FormLabel>
              <InputText
              keyfilter="alpha"
              onChange={(e) => {
                setUserNombre(e.target.value);
                    }}
                    defaultValue=""
                    />
                    
                    </FormControl>
                    </Grid>
                    
                    <Grid item xs={6}>
                    <FormControl>
                  <FormLabel>APELLIDO:</FormLabel>
                  <InputText
                  keyfilter="alpha"
                  onChange={(e) => {
                    setUserApellido(e.target.value);
                  }}
                  defaultValue=""
                  />
                  </FormControl>
                </Grid> */}
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>CUIT:</FormLabel>
                  <InputText
                    keyfilter="num"
                    onChange={handleEmpCuitChange}
                    value={empcuit}
                    placeholder="Sin guiones ni puntos"
                  />
                  {showError && <span style={{ color: "red" }}>El CUIT debe tener al menos 10 números</span>}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>E-MAIL:</FormLabel>
                  <InputText
                    onChange={handleMail}
                    placeholder="ejemplo@mail.com"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>CONTRASEÑA:</FormLabel>
                  <Password
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    promptLabel="Ingrese su contraseña"
                    weakLabel="Baja seguridad"
                    mediumLabel="Mediana Seguridad"
                    strongLabel="Alta Seguridad"
                    toggleMask
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>CONFIRMAR CONTRASEÑA:</FormLabel>
                  <Password
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    promptLabel="Confirme su contraseña"
                    weakLabel="Baja seguridad"
                    mediumLabel="Mediana Seguridad"
                    strongLabel="Alta Seguridad"
                    toggleMask
                  />
                </FormControl>
              </Grid>


              {/*                 EMPRESA                   */}

              <Grid item xs={12}>
                <Item style={{ backgroundColor: "#1976d2", color: "white", fontFamily: "Verdana" }}>Ingrese los datos de su empresa</Item>
                <h6>*Todos los datos son obligatorios</h6>
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>RAZON SOCIAL:</FormLabel>
                  <InputText
                    onChange={(e) => {
                      setRazonSocial(e.target.value);
                    }}
                    defaultValue=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>CUIT:</FormLabel>
                  <InputText
                    keyfilter="num"
                    onChange={handleEmpCuitChange}
                    value={empcuit}
                    disabled
                    placeholder="Sin guiones ni puntos"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>EMAIL:</FormLabel>
                  <InputText
                    onChange={handleMail}
                    value={email}
                    placeholder="ejemplo@mail.com"
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>DIRECCION:</FormLabel>
                  <InputText
                    onChange={(e) => {
                      setEmpDireccion(e.target.value);
                    }}
                    defaultValue=""
                  />
                </FormControl>
              </Grid>


              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>Departamento:</FormLabel>
                  <Dropdown
                    value={empdepartamento}
                    onChange={(e) => setEmpDepartamento(e.target.value)}
                    options={Object.keys(departamentosALocalidades)}
                    placeholder="Seleccione el departamento"
                  />
                </FormControl>
              </Grid>

              <DevuelvoLocalidades />

              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>PROVINCIA:</FormLabel>
                  <InputText
                    defaultValue="Misiones"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>CODIGO POSTAL:</FormLabel>
                  <InputText
                    onChange={(e) => {
                      setEmpcodpostal(e.target.value);
                    }}
                    keyfilter="num"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>TELEFONO:</FormLabel>
                  <InputText
                    onChange={(e) => {
                      setEmptelefono(e.target.value);
                    }}
                    keyfilter="num"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>RAMA:</FormLabel>
                  <Dropdown value={emprama} onChange={(e) => setEmprama(e.target.value)} options={ramas}
                    placeholder="Seleccione la rama" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>INICIO AFIP:</FormLabel>
                  <Input
                    onChange={(e) => {
                      const selectedDate = new Date(e.target.value);
                      const currentDate = new Date();

                      if (selectedDate > currentDate) {
                        e.target.value = ""; // Limpiar el valor seleccionado
                        Swal.fire({
                          icon: 'error',
                          title: 'Selección incorrecta',
                          text: 'No se puede seleccionar una fecha futura',
                        })
                      } else {
                        setEmpinicioafip(e.target.value); // Actualizar el estado con la fecha seleccionada
                      }
                    }}
                    required
                    type="date"
                    fullWidth
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>INICIO DE ACTIVIDAD:</FormLabel>
                  <Input
                    onChange={(e) => {
                      const selectedDate = new Date(e.target.value);
                      const currentDate = new Date();

                      if (selectedDate > currentDate) {
                        e.target.value = ""; // Limpiar el valor seleccionado
                        Swal.fire({
                          icon: 'error',
                          title: 'Selección incorrecta',
                          text: 'No se puede seleccionar una fecha futura',
                        })
                      } else {
                        setEmpinicioactivempleador(e.target.value);; // Actualizar el estado con la fecha seleccionada
                      }
                    }}
                    required
                    type="date"
                    fullWidth
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Item style={{ backgroundColor: "#1976d2", color: "white", fontFamily: "Verdana" }}>Ingrese los datos del contador</Item>
                <h6>*Todos los datos son obligatorios</h6>
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>NOMBRE:</FormLabel>
                  <InputText
                    keyfilter={/^[a-zA-ZñÑ\s]*$/}
                    onChange={(e) => {
                      setContNombre(e.target.value);
                    }}
                    defaultValue=""
                  />

                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>APELLIDO:</FormLabel>
                  <InputText
                    keyfilter={/^[a-zA-ZñÑ\s]*$/}
                    onChange={(e) => {
                      setContApellido(e.target.value);
                    }}
                    defaultValue=""
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>DIRECCION:</FormLabel>
                  <InputText
                    onChange={(e) => {
                      setContDireccion(e.target.value);
                    }}
                    defaultValue=""
                  />

                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>TELEFONO:</FormLabel>
                  <InputText
                    onChange={(e) => {
                      setContTelefono(e.target.value);
                    }}
                    keyfilter="num"
                  />
                </FormControl>
              </Grid>
              
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>MAIL:</FormLabel>
                  <InputText
                    onChange={(e) => {
                      setContaMail(e.target.value);
                    }}
                   
                  />
                </FormControl>
              </Grid>



              <Grid item xs={12}>
                <Item style={{ backgroundColor: "#d6cfcf" }}>
                  <Stack direction="row" spacing={2}>
                    <BtnEnviar />
                  </Stack>
                </Item>
              </Grid>
            </Grid>

          </Box>
        </CardContent>
      </Card>
    </>
  );

  const hayEmpresa = (
    <>
      <Link to={"/"}>
        {" "}
        <button className="btn">INICIAR SESION</button>
      </Link>
    </>
  );



  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {formulario}
    </div>
  );
};
export default SignUp;