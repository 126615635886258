import "./perfil.css";
import React, { useEffect } from "react";
import { Divider } from "primereact/divider";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { ProductService } from "../../service/ProductService";
import { admin, empresaId, userId } from "../utils/LocalStorage";
import Swal from "sweetalert2";
import Btn from "@mui/material/Button";
import { Dropdown } from "primereact/dropdown";
const Perfil = () => {
  const [checked, setChecked] = useState(false);
  const [users, setUsers] = useState({});
  const productService = new ProductService(empresaId);
  const [empresa, setEmpresa] = useState();

  // Usuario
  const [email, setEmail] = useState("");
  const [userNombre, setUserNombre] = useState("");
  const [userApellido, setUserApellido] = useState("");
  const [userEmail, setUserEmail] = useState("");

  // Empresa
  const [razonsocial, setRazonSocial] = useState("");
  const [empemail, setEmpEmail] = useState("");
  const [empemailUser, setEmpEmailUser] = useState("");
  const [empdireccion, setEmpDireccion] = useState("");
  const [emplocalidad, setEmplocalidad] = useState("");
  const [empprovincia, setEmpprovincia] = useState("");
  const [empcodpostal, setEmpcodpostal] = useState("");
  const [empdepartamento, setEmpDepartamento] = useState("");
  const [emptelefono, setEmptelefono] = useState("");
  const [empinicioafip, setEmpinicioafip] = useState("");
  const [empinicioactivempleador, setEmpinicioactivempleador] = useState("");
  const [emprama, setEmprama] = useState("");
  const [showError, setShowError] = useState(false);
  const [empcuit, setEmpCuit] = useState("");
  const [cuit, setCuit] = useState("");
  const [dni, setDni] = useState("");
  const [contadorMail, setContadorMail] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (empresaId !== undefined && userId !== undefined) {
        try {
          const userValue = await productService.getUserById(userId);
          setUsers(userValue);

          const empresaData = await productService.getEmpresaById(empresaId);
          
          setEmpresa(empresaData);
          if (empresaData?.departamento) {
            setEmpDepartamento(empresaData.departamento);
          }
        } catch (error) {
          console.error("Error al obtener datos:", error);
        }

      }
    };

    fetchData();
  }, []);

  const updateUser = () => {
    
    const body = {
      user: {
        apellido: userApellido == "" ? users.apellido : userApellido,
        nombre: userNombre == "" ? users.nombre : userNombre,
        cuit:
        (admin && userId == 1) || (!admin && empresaId !== 1)
        ? empcuit == ""
        ? users.cuil
        : empcuit
        : dni == ""
        ? users.cuil
        : dni,
        email:
        (admin && userId == 1) || (!admin && empresaId !== 1)
        ? empemail == ""
        ? users.email
        : empemail
        : userEmail == ""
        ? users.email
        : userEmail,
        /* email: empemail == "" ? users.email : empemail, */
        userId: userId,
      },
      business: {
        cuit: empcuit == "" ? empresa.cuit : empcuit,
        razon_social: razonsocial == "" ? empresa.razon_social : razonsocial,
        email: empemail == "" ? empresa?.email : empemail,
        telefono: emptelefono == "" ? empresa?.telefono : emptelefono,
        cod_postal: empcodpostal == "" ? empresa?.cod_postal : empcodpostal,
        provincia: empprovincia == "" ? empresa?.provincia : empprovincia,
        localidad: emplocalidad == "" ? empresa?.localidad : emplocalidad,
        direccion: empdireccion == "" ? empresa?.direccion : empdireccion,
        departamento:
        empdepartamento == "" ? empresa?.departamento : empdepartamento,
        inicio_activ_empleador:
        empinicioactivempleador == ""
        ? empresa?.inicio_activ_empleador
        : empinicioactivempleador,
        inicio_afip: empinicioafip == "" ? empresa?.inicio_afip : empinicioafip,
        rama: emprama == "" ? empresa?.rama : emprama,        
        contador_mail: contadorMail === "" ? empresa?.contador_mail : contadorMail,
        EmpresaId: empresaId,
      },
    };
    
    productService.updateUserAndBusiness(body).then((value) => {
      Swal.fire({
        title: "Se han guardado los datos exitosamente!",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "blue",
        confirmButtonText: "OK",
      });
      setChecked(false);
    });
  };

  const ramas = ["CARPINTERIA", "ASERRADERO", "TERCIADO", "AGLOMERADO"];

  const handleMail = (e) => {
    setEmail(e.target.value);
    setEmpEmail(e.target.value);
  };
  const handleMailUser = (e) => {
    setEmail(e.target.value);
    setUserEmail(e.target.value);
  };
  const handleEmpCuitChange = (e) => {
    const formattedCuit = e.target.value.replace(/\D/g, "").slice(0, 11); // Elimina cualquier caracter no numérico y limita a 11 caracteres
    setEmpCuit(formattedCuit);
    setCuit(formattedCuit);
    setShowError(formattedCuit.length < 10);
  };
  const handleEmpDniChange = (e) => {
    const formattedDni = e.target.value.replace(/\D/g, "").slice(0, 11); // Elimina cualquier caracter no numérico y limita a 11 caracteres
    setEmpCuit(formattedDni);
    setDni(formattedDni);
    setShowError(formattedDni.length < 7);
  };
  /* 
     POR CADA DEPARTAMENTO QUE HAYA QUE AGREGAR, AGREGAR ARRAY DE LOCALIDADES.
     EN CASO DE QUE HAYA QUE AGREGAR DPTOS, AGREGAR EN DevuelvoLocalidades OTRO ELSE IF
     QUE DEVUELVA EL DROPDOWN CON LAS LOCALIDADES CORRESPONDIENTES
    
    */
     const departamentos = [
      "APOSTOLES",
      "CAINGUAS",
      "CANDELARIA",
      "CAPITAL",
      "CONCEPCION",       
      "LIBERTADOR SAN MARTIN",
      "LEANDRO N. ALEM",
      "OBERA",
      "SAN IGNACIO",
      "SAN JAVIER",  
      "VEINTICINCO DE MAYO"
    ];
    
    const localidadesPorDepartamento = {
      "APOSTOLES": ["APOSTOLES", "AZARA", "SAN JOSE", "TRES CAPONES"],
      "CAINGUAS": ["ARISTOBULO DEL VALLE", "CAMPO GRANDE", "2 DE MAYO"],
      "CANDELARIA": ["BONPLAND", "CANDELARIA", "CERRO CORA", "LORETO", "MARTIRES", "PROFUNDIDAD", "SANTA ANA"],
      "CAPITAL": ["FACHINAL", "GARUPA", "POSADAS"],
      "CONCEPCION": ["CONCEPCION DE LA SIERRA", "SANTA MARIA"],      
      "LIBERTADOR SAN MARTIN": ["CAPIOVI", "EL ALCAZAR", "GARUHAPE", "PUERTO LEONI", "PUERTO RICO", "RUIZ DE MONTOYA"],
      "LEANDRO N. ALEM": ["ALMAFUERTE", "ARROYO DEL MEDIO", "CAA-YARI", "CERRO AZUL", "DOS ARROYOS", "GOBERNADOR LOPEZ", "LEANDRO N. ALEM", "OLEGARIO VICTOR ANDRADE"],
      "OBERA": ["CAMPO RAMON", "CAMPO VIERA", "COLONIA ALBERDI", "GENERAL ALVEAR", "GUARANI", "LOS HELECHOS", "OBERA", "PANAMBI", "SAN MARTIN"],
      "SAN IGNACIO": ["COLONIA POLANA", "CORPUS", "GENERAL URQUIZA", "GOBERNADOR ROCA", "HIPOLITO IRIGOYEN", "JARDIN AMERICA", "SAN IGNACIO", "SANTO PIPO"],
      "SAN JAVIER": ["FLORENTINO AMEGHINO", "ITACARUARE", "MOJON GRANDE", "SAN JAVIER"],
      "VEINTICINCO DE MAYO": ["ALBA POSSE", "COLONIA AURORA", "25 DE MAYO", "COLONIA ALICIA"]
    };
    
    const DevuelvoLocalidades = (param) => {
      if (empdepartamento === "" && empresa?.localidad == null) {
        return (
          <>
            <div className="flex flex-column gap-2">
              <label htmlFor="localidad">Localidad</label>
              <Dropdown
                disabled
                placeholder="Debe primero elegir el Departamento"
              />
            </div>
          </>
        );
      } else {
        let dptoActual = empdepartamento;
        if (dptoActual.includes(" ")) {
          dptoActual = dptoActual.replace(/\s/g, " ");
        }
        if (dptoActual.includes(".")) {
          dptoActual = dptoActual.replace(/\./g, ".");
        }
        const localidades =
          localidadesPorDepartamento[dptoActual] || empresa?.localidad;
    
        return (
          <>
            <div className="flex flex-column gap-2">
              <label htmlFor="localidad">Localidad</label>
              <Dropdown
                disabled={checked ? false : true}
                value={emplocalidad || empresa?.localidad}
                defaultValue={emplocalidad || empresa?.localidad}
                onChange={(e) => setEmplocalidad(e.target.value)}
                options={localidades}
                placeholder="Seleccione la localidad"
              />
            </div>
          </>
        );
      }
    };

  return (
    <div id="perfilContainer">
      <div>
        <h4 id="tittle">Información de la cuenta</h4>
        <div id="switch">
          <h6>Habilite el switch para editar sus datos</h6>
          <InputSwitch
            checked={checked}
            onChange={(e) => setChecked(e.value)}
          />
        </div>
      </div>

      {/* 
      
      DATOS DEL USUARIO
      
      */}

      <Divider style={{ minHeight: "100px" }} />
      <div id="datauser">
        <div id="empresaContainer">
          <div id="idatos">
            <h4 id="tittle">Datos de ingreso</h4>
          </div>

          <Divider layout="vertical" style={{ minHeight: "100px" }} />

          <div id="datosingreso">
            {/* SI ES ADMIN Y USERID NO ES 1 - MUESTRA NOMBRE APELLIDO Y DNI - VISTA USUARIO */}
            {admin && userId !== 1 ? (
              <>
                <div className="flex flex-column gap-2">
                  <label htmlFor="nombre">Nombre</label>
                  <InputText
                    disabled={checked ? false : true}
                    defaultValue={users?.nombre}
                    keyfilter="alpha"
                    id="nombree"
                    aria-describedby="username-help"
                    onChange={(e) => {
                      setUserNombre(e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-column gap-2">
                  <label htmlFor="apellido">Apellido</label>
                  <InputText
                    disabled={checked ? false : true}
                    defaultValue={users?.apellido}
                    keyfilter="alpha"
                    id="apellidoo"
                    aria-describedby="username-help"
                    onChange={(e) => {
                      setUserApellido(e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-column gap-2">
                  <label htmlFor="cuit">DNI</label>
                  <InputText
                    disabled={checked ? false : true}
                    defaultValue={users?.cuil}
                    onChange={handleEmpDniChange}
                    id="cuitt"
                    keyfilter="num"
                    aria-describedby="cuit-help"
                    className={showError ? "p-invalid" : ""}
                    maxLength={8}
                  />
                  {showError && (
                    <span style={{ color: "red" }}>
                      El DNI debe tener al menos 7 números
                    </span>
                  )}
                </div>
                <div className="flex flex-column gap-2">
                  <label htmlFor="email">Email</label>
                  <InputText
                    disabled={checked ? false : true}
                    defaultValue={users?.email}
                    id="emaill"
                    aria-describedby="email-help"
                    onChange={handleMailUser}
                  />
                </div>
              </>
            ) : (
              ""
            )}
            {/* SI ES ADMIN Y USER ID = 1 o NO ES ADM Y EMPRESAID !== 1  MUESTRA VISTA SINDICATO o VISTA EMPRESA(DEPENDIENDO DEL USUARIO LOGUEADO)*/}
            {(admin && userId == 1) || (!admin && empresaId !== 1) ? (
              <>
                <div className="flex flex-column gap-2">
                  <label htmlFor="cuit">CUIT</label>
                  <InputText
                    disabled={checked ? false : true}
                    defaultValue={empresa?.cuit}
                    onChange={handleEmpCuitChange}
                    id="cuitt"
                    keyfilter="num"
                    aria-describedby="cuit-help"
                    className={showError ? "p-invalid" : ""}
                    maxLength={11}
                  />
                  {showError && (
                    <span style={{ color: "red" }}>
                      El CUIT debe tener al menos 10 números
                    </span>
                  )}
                </div>
                <div className="flex flex-column gap-2">
                  <label htmlFor="email">Email</label>
                  <InputText
                    disabled={checked ? false : true}
                    defaultValue={empresa?.email}
                    id="emaill"
                    aria-describedby="email-help"
                    onChange={handleMail}
                  />
                </div>{" "}
              </>
            ) : (
              ""
            )}

            {/* <div className="flex flex-column gap-2">
              <label htmlFor="cuit">CUIT</label>
              <InputText
                disabled={checked ? false : true}
                defaultValue={users?.cuil}
                onChange={handleEmpCuitChange}
                keyfilter="num"
                id="cuil"
                aria-describedby="cuil-help"
              />
            </div> */}
            {/*            </div>
            <div className="flex flex-column gap-2">
              <label htmlFor="nombre">Nombre</label>
              <InputText
                disabled={checked ? false : true}
                defaultValue={users?.nombre}
                keyfilter="alpha"
                id="nombre"
                aria-describedby="username-help"
                onChange={(e) => {
                  setUserNombre(e.target.value);
                }}
              />
              </div>
            </div>

            <div style={{ marginLeft: "5px", marginRight: "50px" }}>
            <div className="flex flex-column gap-2">
              <label htmlFor="apellido">Apellido</label>
              <InputText
                disabled={checked ? false : true}
                defaultValue={users?.apellido}
                keyfilter="alpha"
                id="apellido"
                aria-describedby="username-help"
                onChange={(e) => {
                  setUserApellido(e.target.value);
                }}
              />
            </div> */}
          </div>
        </div>

        {/* 
        
        DATOS DE LA EMPRESA
        
        */}

        <Divider style={{ minHeight: "100px" }} />
        <div id="empresaContainer1">
          <div>
            <h4>Datos de la empresa</h4>
          </div>

          <Divider layout="vertical" style={{ minHeight: "300px" }} />

          {/* 
          
          FILA UNO

          */}

          <div id="filauno">
            {/* VISTA USUARIO */}
            {admin && userId !== 1 ? (
              <>
                <div className="flex flex-column gap-2">
                  <label htmlFor="cuit">CUIT</label>
                  <InputText
                    disabled={checked ? false : true}
                    defaultValue={empresa?.cuit}
                    onChange={(e) => {
                      setEmpCuit(e.target.value);
                    }}
                    id="cuit"
                    keyfilter="num"
                    aria-describedby="cuit-help"
                    maxLength={11}
                  />
                </div>
                <div className="flex flex-column gap-2">
                  <label htmlFor="email">Email</label>
                  <InputText
                    disabled={checked ? false : true}
                    defaultValue={empemail !== "" ? email : empresa?.email}
                    id="email"
                    aria-describedby="email-help"
                    onChange={(e) => {
                      setEmpEmail(e.target.value);
                    }}
                  />
                </div>{" "}
              </>
            ) : (
              ""
            )}
            {/* VISTA EMPRESA / SINDICATO */}
            {(admin && userId == 1) || (!admin && empresaId !== 1) ? (
              <>
                <div className="flex flex-column gap-2">
                  <label htmlFor="cuit">CUIT</label>
                  <InputText
                    disabled
                    defaultValue={empcuit !== "" ? cuit : empresa?.cuit}
                    onChange={(e) => {
                      setEmpCuit(e.target.value);
                    }}
                    id="cuit"
                    keyfilter="num"
                    aria-describedby="cuit-help"
                  />
                </div>{" "}
                <div className="flex flex-column gap-2">
                  <label htmlFor="email">Email</label>
                  <InputText
                    disabled
                    defaultValue={empemail !== "" ? email : empresa?.email}
                    id="email"
                    aria-describedby="email-help"
                    onChange={(e) => {
                      setEmpEmail(e.target.value);
                    }}
                  />
                </div>{" "}
              </>
            ) : (
              ""
            )}

            <div className="flex flex-column gap-2">
              <label htmlFor="departamento">Departamento</label>
              <Dropdown
                disabled={checked ? false : true}
                defaultValue={empresa?.departamento}
                value={empdepartamento || empresa?.departamento}
                onChange={(e) => setEmpDepartamento(e.target.value)}
                options={departamentos}
                placeholder="Seleccione el departamento"
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="provincia">Provincia</label>
              <InputText
                disabled
                defaultValue={empresa?.provincia}
                id="provincia"
                aria-describedby="provincia-help"
                onChange={(e) => {
                  setEmpprovincia(e.target.value);
                }}
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="telefono">Teléfono</label>
              <InputText
                disabled={checked ? false : true}
                defaultValue={empresa?.telefono}
                id="telefono"
                keyfilter="num"
                aria-describedby="telefono-help"
                onChange={(e) => {
                  setEmptelefono(e.target.value);
                }}
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="inicioafip">Inicio de Actividad AFIP</label>
              <input
                disabled={checked ? false : true}
                id="inicioafip"
                type="date"
                defaultValue={
                  empresa?.inicio_afip
                    ? new Date(empresa.inicio_afip).toISOString().split("T")[0]
                    : ""
                }
                onChange={(e) => {
                  const selectedDate = new Date(e.target.value + "T00:00:00");
                  const currentDate = new Date();
                  if (selectedDate > currentDate) {
                    e.target.value = "";
                    Swal.fire({
                      icon: 'error',
                      title: 'Selección incorrecta',
                      text: 'No se puede seleccionar una fecha futura',
                    });
                  } else {
                    setEmpinicioafip(e.target.value);
                  }
                }}
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="contMail">Contador Mail</label>
              <InputText
                disabled={checked ? false : true}
                defaultValue={empresa?.contador_mail}
                id="contMail"
                aria-describedby="contMail-help"
                onChange={(e) => {
                  setContadorMail(e.target.value);
                }}
              />
            </div>

            {checked ? (
              <Btn
                variant="contained"
                onClick={updateUser}
                style={{ marginTop: "5px" }}
              >
                {" "}
                Actualizar Datos{" "}
              </Btn>
            ) : (
              ""
            )}
          </div>

          {/* 
          
          FILA DOS
          
          
          */}

          <div id="filados">
            <div className="flex flex-column gap-2">
              <label htmlFor="razsoc">Razon Social</label>
              <InputText
                disabled={checked ? false : true}
                defaultValue={empresa?.razon_social}
                id="razsoc"
                aria-describedby="razsoc-help"
                onChange={(e) => {
                  setRazonSocial(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-column gap-2">
              <label htmlFor="direccion">Dirección</label>
              <InputText
                disabled={checked ? false : true}
                defaultValue={empresa?.direccion}
              />
            </div>

            <DevuelvoLocalidades />

            <div className="flex flex-column gap-2">
              <label htmlFor="codpostal">Código Postal</label>
              <InputText
                disabled={checked ? false : true}
                defaultValue={empresa?.cod_postal}
                onChange={(e) => setEmpcodpostal(e.target.value)}
              />
            </div>
            <div className="flex flex-column gap-2">
              <label htmlFor="rama">Rama</label>
              <Dropdown
                disabled={checked ? false : true}
                value={emprama || empresa?.rama} // Utiliza 'value' en lugar de 'defaultValue'
                onChange={(e) => setEmprama(e.target.value)}
                options={ramas}
                placeholder="Seleccione la rama"
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="inicioactividad">Inicio de Actividad</label>
              <input
                disabled={checked ? false : true}
                id="inicioactividad"
                type="date"
                defaultValue={
                  empresa?.inicio_activ_empleador
                    ? new Date(empresa.inicio_activ_empleador)
                      .toISOString()
                      .split("T")[0]
                    : ""
                }
                onChange={(e) => {
                  const selectedDate = new Date(e.target.value + "T00:00:00");
                  const currentDate = new Date();
                  if (selectedDate > currentDate) {
                    e.target.value = "";
                    Swal.fire({
                      icon: 'error',
                      title: 'Selección incorrecta',
                      text: 'No se puede seleccionar una fecha futura',
                    });
                  } else {
                    setEmpinicioactivempleador(e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider style={{ minHeight: "100px" }} />
    </div>
  );
};

export default Perfil;
