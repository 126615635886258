import React, { useState, useEffect } from 'react';
import TablaIntereses from './TablaIntereses';
import InputIntereses from './InputIntereses';
import { ProductService } from '../../service/ProductService';
import Swal from 'sweetalert2';

const GestorIntereses = () => {
    const [intereses, setIntereses] = useState([]);
    const [loading, setLoading] = useState(true);
    const productService = new ProductService();

    useEffect(() => {
        fetchIntereses();
    }, []);

    const fetchIntereses = async () => {
        setLoading(true);
        try {
            const data = await productService.getInteresesForTable();
            const sortedData = data.sort((a, b) => new Date(b.mesanio) - new Date(a.mesanio));
            setIntereses(sortedData);
        } catch (error) {
            console.error("Error fetching intereses:", error);
            Swal.fire({
                title: 'Error al cargar intereses',
                icon: 'error',
                confirmButtonColor: 'red',
                confirmButtonText: 'OK'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
            <InputIntereses fetchIntereses={fetchIntereses} intereses={intereses} />
            <TablaIntereses intereses={intereses} fetchIntereses={fetchIntereses} loading={loading} />
        </div>
    );
};

export default GestorIntereses;
