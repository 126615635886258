import { Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import { ProductService } from "../../service/ProductService";
import Swal from "sweetalert2";
import CircularProgress from '@mui/material/CircularProgress';

const ResetPw = () => {
  const [email, setEmail] = useState("");
  const [sendedReset, setSendedReset] = useState(false);
  const [first, setFirst] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const sendReset = () => {
    if (!email || !validateEmail(email)) {
      setEmailError(true);
      return;
    }

    setSendedReset(true);
    setFirst(true);

    const _service = new ProductService();
    const body = { email: email };

    _service.sendEmail(body).then((value) => {
      setSendedReset(false);

      if (value.data?.send) {
        Swal.fire({
          icon: "success",
          text: 'Recibirá un correo con la URL de recuperación de contraseña. Por favor revise su casilla de "correo no deseado".',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/auth");
          }
        });
      } else if (value.data?.noEsta) {
        Swal.fire({
          icon: "error",
          text: 'El email no se encuentra en nuestra base de datos.',
        });
        setFirst(false)
      }
    }).catch((error) => {
      setSendedReset(false);
      Swal.fire({
        icon: "error",
        text: 'Ocurrió un error. Por favor, intente nuevamente.',
      });
      setFirst(false)
    });
  };

  const inputChange = (value) => {
    setEmail(value);
    setEmailError(false);
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          mt: 9,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          minHeight={100}
          sx={{
            maxWidth: "500px",
            border: "1px solid #F5F5F5",
            borderRadius: "13px;",
            boxShadow:
              "-10px 2px 13px 2px #00000033, 10px 2px 13px 2px #00000033, -50px 22px 15px -30px rgba(0,0,0,0);",
            backgroundColor: "#F5F5F5",
            display: "flex",
          }}
          alignItems="center"
        >
          <Grid
            sx={{ marginTop: "15px" }}
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar maxWidth="xs" src="/static/images/avatar/1.jpg" />
          </Grid>
          <Grid justifyContent="center" xs={12} display="flex" sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Recuperar Contraseña
            </Typography>
          </Grid>
          <Grid
            display="flex"
            justifyContent="center"
            xs={12}
            component="form"
            sx={{
              "& > :not(style)": { mt: 2.5, width: "52ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              type="email"
              id="outlined-basic"
              label="Ingrese su Email"
              variant="outlined"
              value={email}
              onChange={(newValue) => inputChange(newValue.target.value)}
              error={emailError}
              helperText={emailError ? "Por favor, ingrese un correo electrónico válido" : ""}
            />
          </Grid>

          <Grid
            sx={{
              marginBottom: "20px",
            }}
            display="flex"
            justifyContent="center"
            xs={12}
          >
            <Link to={"/auth"}>
              <Button
                sx={{ mr: 1, mt: 2, width: "189px" }}
                variant="contained"
                endIcon={<CancelIcon />}
              >
                Volver
              </Button>
            </Link>
            <Button
              onClick={sendReset}
              sx={{ ml: 1, mt: 2, width: "189px" }}
              variant="contained"
              endIcon={<SendIcon />}
              disabled={sendedReset || first}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Box>
      {sendedReset && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: "25px",
          }}
        >
          <h5>Operación en proceso</h5>
          <CircularProgress style={{ marginLeft: "15px" }} />
        </Box>
      )}
    </>
  );
};

export default ResetPw;
