import "./footer.css"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import logoSoimp from "./logoSOIMP.png";
import { useEffect, useState } from "react";
import { ProductService } from "../service/ProductService";

const Footer = () => {
  const [version, setVersion] = useState();
  const productService = new ProductService();

  useEffect(() => {
    productService.getVersion().then((value) => {
      setVersion(value);
    });

  }, []);

  return (
    <footer id="footer">
      <Box id="logosContainer">
        <div id="individualContainer" className="contenedor-imagen">
          <img src={logoSoimp} alt="logo-usimra" id="logosImg" />
          <h6>S.O.I.M.P</h6>
        </div>
      </Box>
      <Typography variant="body2" color="white" align="center" id="copy">
        {'Copyright © '}
        {new Date().getFullYear()}
        <a href="http://www.itdbservices.com/">ITDB Services</a><br />
        Powered by ITDB Services<br />
        Version: {version}
        {'.'}
      </Typography>
    </footer>
  )
}
export default Footer