import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Ddjj from "../components/Ddjj/Ddjj";
import NewDDJJ from "../components/Ddjj/NewDDJJ";
import AccountMenu from "../components/Nav/AccountMenu";
import TablaTrabajadores from "../components/seccionEmpleados/TablaTrabajadores";
import TablaEmpresas from "../seccionEmpresas/TablaEmpresas";
import PaySuccessful from "../components/paySuccessful/PaySuccessful";
import { admin } from "../components/utils/LocalStorage";
import AbmEmployee from "../components/AbmEmployee/AbmEmployee";
import Perfil from "../components/Perfil/Perfil";
import GestorIntereses from "../components/Intereses/GestorIntereses";


const RouterLogged = () => {
  return (
    <>
      <AccountMenu />
      <Routes>        
        <Route path="/ddjj" element={<Ddjj />} />
        <Route path="/nuevaDDJJ" element={<NewDDJJ />} />
        <Route path="/pagoexitoso" element={<PaySuccessful />} />
        <Route path="/abmemp" element={<AbmEmployee />} />
        <Route path="/perfil" element={<Perfil />} />
        {admin ? (
          <>
            <Route path="/empresas" element={<TablaEmpresas />} />
            <Route path="/auth" element={<Navigate to="/empresas" />} />
            <Route path="/" element={<Navigate to="/empresas" />} />
            <Route path="/empleados" element={<TablaTrabajadores />} />
            <Route path="/intereses" element={<GestorIntereses />} />
          </>
        ) : (
          <>
            <Route path="/empleados" element={<TablaTrabajadores />} />
            <Route path="/" element={<Navigate to="/empleados" />} />
            <Route path="/auth" element={<Navigate to="/empleados" />} /> 
          </>
        )}
      </Routes>
    </>
  );
};

export default RouterLogged;
