import React from "react";
import foto from "../utils/encabezado.png";
import pie from "../utils/pie.png";
import "./Ddjj.css"

const ComponentToPrint = React.forwardRef((props, ref) => {
  let { products } = props;
  let { ddjjInfo } = props;

  if (ddjjInfo == undefined) {
    ddjjInfo.peoples.forEach((people, index) => {
      const sueldosArray = ddjjInfo.sueldos.split('|');
      people.ingresos_bruto = sueldosArray[index];
    });
  }



  // Crear un objeto Date a partir de la fecha original
  var fecha = new Date(ddjjInfo.fecha_creacion);

  // Obtener los componentes de la fecha y hora
  var dia = fecha.getDate();
  var mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
  var anio = fecha.getFullYear();
  var horas = fecha.getHours();
  var minutos = fecha.getMinutes();

  // Formatear los componentes en el formato deseado
  var fechaFormateada = ('0' + dia).slice(-2) + '-' + ('0' + mes).slice(-2) + '-' + anio;
  var horaFormateada = ('0' + horas).slice(-2) + ':' + ('0' + minutos).slice(-2);

  // Combinar la fecha y la hora formateadas
  var resultado = fechaFormateada + ' ' + horaFormateada;
  const Impresion = () => {

    let date = new Date(ddjjInfo.fechadeclaracion);
    const ingresosBrutosTotales = ddjjInfo?.peoples?.resultado?.reduce((acumulador, people) => acumulador + Number(people.ingresos_bruto), 0);


    if (ingresosBrutosTotales > 0) {

      return <td className="text-right bg-light"><strong>{ingresosBrutosTotales.toLocaleString("es-AR", { style: "currency", currency: "ARS" })} </strong></td>;
    }
  };
  const RectificacionType = () => {
    const objetosFiltrados = Object.values(products).filter(product => {
      return product.mestributo === ddjjInfo.mestributo && product.rectificacionid !== null;
    });
    const posicion = 1 + objetosFiltrados.findIndex(objetoFiltrado => objetoFiltrado.iddeclaracion === ddjjInfo.iddeclaracion);

    return String("Rectificativa Nº " + posicion);
  }
  const getRectificaAOtraDDJJ = () => {
    return (
      <div>
        <td className="left">
          <strong>Rectifica a la declaración jurada previa con el ID: {ddjjInfo.rectificacionid} </strong>
        </td>
      </div>
    );
  }

  return (
    <div className="main" ref={ref} value={"prod"}>
      <div className="container">
        <div className="card animate__animated animate__fadeIn">
          <div className="card-body">
            <div className="row mb-4">
              <div
                className="col-6 col-md-6 text-left"
                style={{ width: "100vh" }}
              >
                <div style={{ textAlign: "center", marginBottom: "15px" }}>
                  <img src={foto} alt="" style={{ width: "100%", height: "auto" }} />
                </div>
                <tr style={{ textTransform: 'uppercase' }}>
                  <div>
                    <td className="left">
                      <strong>ID: {ddjjInfo.iddeclaracion} </strong>
                    </td>
                  </div>
                  <div>
                    <td className="left">
                      <strong>Empresa: {ddjjInfo.razon_social} </strong>
                    </td>
                  </div>
                  <div>
                    <td className="left">
                      <strong>C.U.I.T: {ddjjInfo.cuit} </strong>
                    </td>
                  </div>
                  <div>
                    <div>
                      <td className="left">
                        <strong>Domicilio: {ddjjInfo.direccion}, {ddjjInfo.localidad}, departamento de {ddjjInfo.departamento}, provincia de {ddjjInfo.provincia} </strong>
                      </td>
                    </div>
                    <td className="left">
                      <strong>Periodo: {ddjjInfo?.mestributo}</strong>
                    </td>
                  </div>
                  <div>
                    <td className="left">
                      <strong>Fecha creacion: {resultado}</strong>
                    </td>
                  </div>
                  <div>
                    <td className="left">
                      <strong>Tipo: {ddjjInfo.rectificacionid == null ? <strong>Original</strong> : RectificacionType()}</strong>
                    </td>
                  </div>
                  {ddjjInfo.rectificacionid != null ? getRectificaAOtraDDJJ() : ""}
                </tr>
              </div>
            </div>

            {/* DATOS DE EMPLEADOS */}

            <div className="table-responsive-sm">
              <table className="table table-md table-striped" style={{ textTransform: 'uppercase' }}>
                <thead className="encabezadoTabla">
                  <tr>
                    <th scope="col" width="2%" className="center">
                      #
                    </th>
                    <th scope="col" width="24%">
                      CUIL
                    </th>
                    <th scope="col" width="24%">
                      Empleado
                    </th>
                    <th scope="col" width="24%" >
                      Remuneración
                    </th>
                    <th scope="col" width="24%">
                      Cuota Sindical 3%
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {ddjjInfo.peoples?.resultado?.map((people, index) => {
                    // Asignar los valores de sueldos a ingresos_bruto
                    const sueldosArray = ddjjInfo.sueldos.split('|');
                    people.ingresos_bruto = sueldosArray[index];

                    return (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{people.cuil}</td>
                        <td className="text-center">
                          {people.nombre.toUpperCase() + " " + people.apellido.toUpperCase()}
                        </td>
                        <td className="text-center">
                          {Number(people.ingresos_bruto).toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
                        </td>
                        <td className="text-center">
                          {((people.ingresos_bruto * 3) / 100).toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* Hasta aca tabla */}

            <div className="row" style={{ justifyContent: "right", textTransform: 'uppercase' }}>
              <div className="col-lg-4 col-sm-5">
                <table className="table table-sm table-striped">
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left' }}>
                        <strong >Remuneración total:</strong>
                      </td>
                      <Impresion />
                    </tr>

                    <tr>
                      <td style={{ textAlign: 'left' }}>
                        <strong>Cuota sindical a pagar:</strong>
                      </td>
                      <td className="text-right bg-light">
                        <strong>{ddjjInfo.monto.toLocaleString("es-AR", { style: "currency", currency: "ARS" })}</strong>
                      </td>
                    </tr>
                    {/* 
                    
                    
                    
                    
                    */}

                    <tr>
                      <td style={{ textAlign: 'left' }}>
                        <strong>Intereses:</strong>
                      </td>
                      <td className="text-right bg-light">
                        <strong>
                          {ddjjInfo.pago == 1
                            ? ddjjInfo.deuda_acumulada?.toLocaleString("es-AR", { style: "currency", currency: "ARS" })
                            : ddjjInfo.ddjjConInteres?.deudaAcumulado?.toLocaleString("es-AR", { style: "currency", currency: "ARS" })
                          }
                        </strong>
                      </td>
                    </tr>

                    {/* test */}

                    <tr>
                      <td style={{ textAlign: 'left' }}>
                        <strong >Total con interes:</strong>
                      </td>
                      {/*  <td className="text-right bg-light">
                        <strong>
                          {ddjjInfo.pago == 1
                            ? Number(ddjjInfo.montoconinteres).toLocaleString("es-AR", { style: "currency", currency: "ARS" })
                            : ddjjInfo.ddjjConInteres?.saldoMasDeudaAPagar?.toLocaleString("es-AR", { style: "currency", currency: "ARS" })
                          }
                        </strong>
                      </td> */}
                      <td className="text-right bg-light">
                        <strong>
                          {ddjjInfo.pago == 1
                            ? Number(ddjjInfo.montoconinteres) === 0
                              ? (Number(ddjjInfo.monto) + Number(ddjjInfo.deuda_acumulada)).toLocaleString("es-AR", { style: "currency", currency: "ARS" })
                              : Number(ddjjInfo.montoconinteres).toLocaleString("es-AR", { style: "currency", currency: "ARS" })
                            : ddjjInfo.ddjjConInteres?.saldoMasDeudaAPagar?.toLocaleString("es-AR", { style: "currency", currency: "ARS" })
                          }
                        </strong>
                      </td>
                    </tr>


                  </tbody>
                </table>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <img src={pie} alt="" style={{ maxWidth: "100%", height: "auto" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default ComponentToPrint
