import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../index.css";
import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "../../service/ProductService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { empresaId, admin, nombre } from "../utils/LocalStorage";
import ComponentToPrint from "./ComponentToPrint";
import ReactToPrint from "react-to-print";
import "./Ddjj.css";
import { Dropdown } from "primereact/dropdown";
import mercadoPago from "./MercadoPago.png"
import pagos360 from "./pagos360.png"
import transferencia from "./transferencia.png"
import soimp from "./logo.png"
import Swal from 'sweetalert2'
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import logo from "../utils/encabezado.png"
import pie from "../utils/pie.png"
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { SplitButton } from 'primereact/splitbutton';
import { PrimeIcons } from 'primereact/api';
import Alerta from "../utils/AlertExport";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Ddjj = (emptyProduct) => {
  const [urlMeli, setUrlMeli] = useState();
  const [urlPagos360, setUrlPagos360] = useState();
  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [paymentNow, setPayNow] = useState(false);
  const [printDdjjDialog, setprintDdjjDialog] = useState(false);
  const [ddjjInfo, setddjjInfo] = useState();
  const [empresas, setEmpresas] = useState();
  const [selectedEmpresa, setSelectedEmpresa] = useState(undefined);
  const [pagoEnEfectivo, setPagoEnEfectivo] = useState(false);
  const [nombreRecibidorPago, setRecibidorPago] = useState("");
  const [montoRecibido, setMontoRecibido] = useState("");
  const [empresaIdSelected, setEmpresaIdSelected] = useState("")
  const [fechaPagoRecibido, setFechaPagoRecibido] = useState(new Date().toLocaleDateString());
  let [selectedFile, setSelectedFile] = useState([]);
  const [pagoConTransferencia, setPagoConTransferencia] = useState(false);
  const [infoEfectivo, setInfoEfectivo] = useState(false);
  const [pago, setPago] = useState()
  const toast = useRef(null);
  const dt = useRef(null);
  const productService = new ProductService(empresaId);
  const [actualProduct, setActualProduct] = useState();
  const [cambioEstadoEmpresa, setCambioEstadoEmpresa] = useState();
  const [disabledForTime, setDisabledForTime] = useState(false);
  const [totalDeclaraciones, setTotalDeclaraciones] = useState(0);
  const [interesesDdjj, setDdjjInteres] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      if (!admin && empresaId != undefined && empresaId != 1) {
        const data = await productService.getDeclaracionesJuradas(empresaId);
        setProducts(data);


        Swal.fire({
          title: "Tenga en cuenta que las DDJJ vencidas generarán intereses tasa activa Banco Nación",
          html: 'Para ver la tasa, visite <a href="https://www.bna.com.ar/home/informacionalusuariofinanciero" target="_blank">este enlace</a>.',
          icon: "warning"
        });


      } else {
        const data = await productService.getEmpresas();
        setEmpresas(data);
      }
      setTimeout(() => {
        setDisabledForTime(false);
      }, 300);
    };
    fetchData();
  }, [cambioEstadoEmpresa]);

  /* MEDIANTE ESTE USE EFFECT RENDEREO EL TOTAL DE LAS DDJJ QUE UNA EMPRESA ADEUDE PARA MOSTRAR EN EL FOOTER DE LA TABLA */
  useEffect(() => {
    if (products) {
      let total = 0;
      products.forEach((product) => {
        if (product.ddjjConInteres) {
          total += product.ddjjConInteres.saldoMasDeudaAPagar;
        }
      });
      setTotalDeclaraciones(total);
    }
  }, [products]); 

  //dejo esta funcion comentada, es para resolver que las recitficada no sumen en el total que se muestra en el pie de la tabla
/*   useEffect(() => {
    if (products) {
      const productsByMestributo = {};
  
      // Agrupar productos por mestributo
      products.forEach((product) => {
        if (!productsByMestributo[product.mestributo]) {
          productsByMestributo[product.mestributo] = product;
        } else {
          // Comparar rectificacionId y mantener el de mayor valor
          if (product.rectificacionId > productsByMestributo[product.mestributo].rectificacionId) {
            productsByMestributo[product.mestributo] = product;
          }
        }
      });
  
      let total = 0;
      // Sumar saldoMasDeudaAPagar de los productos filtrados
      Object.values(productsByMestributo).forEach((product) => {
        if (product.ddjjConInteres) {
          total += product.ddjjConInteres.saldoMasDeudaAPagar;
        }
      });
  
      setTotalDeclaraciones(total);
    }
  }, [products]);
 */



  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
    setprintDdjjDialog(false);
  };


  const getInfoddjjbyid = async (id) => {
    await productService.getDeclaracionJuradaById(id).then((value) => {
      setddjjInfo(value);
      setprintDdjjDialog(true);
      setDdjjInteres({
        resarcitorios: value.ddjjConInteres?.deudaAcumulado,
        montoActualizado: value.ddjjConInteres?.saldoMasDeudaAPagar
      })
    });
  };
  const onInputChange = (e, name) => {
    if (name == "nombre") {
      setRecibidorPago(e.target.value);
    }
    if (name == "monto") {
      setMontoRecibido(e.target.value);
    }
  };


  const printDdjj = (product) => {
    getInfoddjjbyid(product.iddeclaracion);
  };



  const hideDialogPagoConTransferencia = () => {
    setPagoConTransferencia(false);
  };


  const sendFile = async () => {
    const body = new FormData();
    body.append('myFile', selectedFile, selectedFile.name);
    await productService.sendFile(body, actualProduct.iddeclaracion).then((value) => { });
  };
  const fileOnChange = (event) => {
    const [file] = event.target.files;
    setSelectedFile(file);
  };
  const selectedempresa = (value) => {
    setSelectedEmpresa(value);
    setEmpresaIdSelected(value)
    productService
      .getDeclaracionesJuradas(value.empresaid)
      .then((value) => {
        setProducts(value);

      });
  };

  const hideDialogPagarAhora = () => {
    setCambioEstadoEmpresa(true)
    setPayNow(false);

  };
  const hideDialogPagoEnEfectivo = () => {
    setPagoEnEfectivo(false);
  };

  const payPopUpNow = async (product) => {

    setActualProduct(product);
    if (admin){
      setPayNow(true)
      return
    }

    const success = await createLinkPaymentPagos360(product);

    if (success) {
      setPayNow(true);
    }
  };
 
  const createLinkPaymentPagos360 = async (product) => {
    const { admin } = JSON.parse(localStorage.getItem('session'));

    if (admin) {
      return false; // Salir y no cambiar el estado
    }
    if (product.link_de_pago != null) {
      setUrlPagos360(product.link_de_pago);
      return true; // Indicar que se ejecutó correctamente
    }

    const body = {
      empresaId: product.empresaid,
      idDeclaracion: product.iddeclaracion,
      intereses: product.ddjjConInteres ? product.ddjjConInteres.deudaAcumulado : 0,
      payment_request: {
        description: product.mestributo,
        first_total: product.ddjjConInteres ? product.ddjjConInteres.saldoMasDeudaAPagar : product.monto
      }
    };

    const payment = {};

    try {
      const value = await productService.payWithPagos360(body);
      setUrlPagos360(value.url);

      payment.idDePago = value.idPay;
      payment.linkDePago = value.url;
      payment.ddjjId = product.iddeclaracion;

      await productService.updateLinkPaymentAndId(payment);

      await productService.actualizoDatosPagosDdjj(body);
      return true;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Pagos 360 no se encuentra disponible. Intente más tarde.'
      });
      return false;
    }
  }



  /* FUNCION PARA EXPORTAR EN EXCEL */

  const exportExcel = () => {
    if (selectedProducts == null || selectedProducts.length == 0) {
      Swal.fire({
        icon: 'error',
        title: `SELECCION INCOMPLETA`,
        text: ` Debe seleccionar las filas `,
      })
    } else {

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Datos seleccionados');

      // Establecer encabezados de columna
      const headers = [
        'PERIODO',
        'TIPO',
        'MONTO INICIAL',
        'INTERESES APLICADOS',
        'MONTO ACTUALIZADO',
        'ESTADO',
      ];
      worksheet.addRow(headers);

      // Agregar filas de datos
      selectedProducts.forEach((ddjj) => {
        const rowData = [
          ddjj.mestributo,
          ddjj.rectificacionid == null ? 'Original' : `Rectifica a : ${ddjj.rectificacionid}`,
          ddjj.monto,
          ddjj.ddjjConInteres?.deudaAcumulado,
          ddjj.ddjjConInteres?.saldoMasDeudaAPagar,
          ddjj.pago,
        ];
        worksheet.addRow(rowData);
      });

      // Establecer estilo para los encabezados de columna
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '0080ff' },
        };
      });


      // Ajustar el ancho de las columnas automáticamente
      worksheet.columns.forEach((column) => {
        column.width = 15;
      });


      // Generar el archivo Excel
      workbook.xlsx.writeBuffer().then((buffer) => {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, 'ListadoDDJJ.xlsx');
      });

    }
  };


  /* FUNCION PARA EXPORTAR A PDF */



  const exportPDF = async () => {
    if (selectedProducts == null || selectedProducts.length == 0) {
      Alerta();
      return; // Salir de la función si no hay productos seleccionados
    }

    try {
      // Cargar la imagen del logo
      const logoResponse = await fetch(logo);
      const logoBlob = await logoResponse.blob();
      const base64Logo = await convertirImagenABase64Async(logoBlob);

      // Cargar la imagen de pie
      const pieResponse = await fetch(pie);
      const pieBlob = await pieResponse.blob();
      const base64Pie = await convertirImagenABase64Async(pieBlob);

      const cellStyle = {
        fontSize: 8,
      };

      const formatCurrency = (value) => {
        // Agregar el signo "$" adelante del valor y formatear según tus necesidades
        return `$${value}`;
      };

      const formatPorcentaje = (value) => {
        // Agregar el signo "$" adelante del valor y formatear según tus necesidades
        return `${value.toFixed(1)}%`;
      };

      const formatPago = (value) => {
        // Mostrar "pago" si el valor es 1, de lo contrario mostrar "impago"
        return value === 1 ? 'PAGO' : 'IMPAGO';
      };

      const formatCUIT = (cuit) => {
        if (typeof cuit === 'string' && cuit.length >= 11) {
          // Verifica si el CUIT ya está en el formato correcto
          const regex = /^\d{2}-\d{8}-\d{1}$/;
          if (regex.test(cuit)) {
            return cuit;
          }

          // Asegúrate de que el CUIT tenga al menos 11 dígitos
          const cleanedCuit = cuit.replace(/[^0-9]/g, ''); // Elimina cualquier carácter no numérico
          if (cleanedCuit.length === 11) {
            return `${cleanedCuit.slice(0, 2)}-${cleanedCuit.slice(2, 10)}-${cleanedCuit.slice(10)}`;
          }
        }
        return cuit;
      };


      const table = {
        headerRows: 1,
        widths: [60, 80, 80, 90, 90, 60, 120, 60],
        body: [
          [
            { text: 'PERIODO', style: 'header' },
            { text: 'TIPO', style: 'header' },
            { text: 'MONTO INICIAL', style: 'header' },
            { text: 'INTERÉS ACUMULADO', style: 'header' }, // Nueva cabecera para Interés Acumulado
            { text: 'DÍAS DE MORA', style: 'header' }, // Nueva cabecera para Días de Mora          
            { text: 'MONTO ACTUALIZADO', style: 'header' },
            { text: 'ESTADO', style: 'header' },
          ],
          ...selectedProducts.map((ddjj) => {
            // Calcular días de mora
            const fechaDeclaracion = new Date(ddjj.fechadeclaracion);
            const fechaActual = new Date();
            const diferenciaEnTiempo = fechaActual - fechaDeclaracion;
            const diferenciaEnDias = Math.floor(diferenciaEnTiempo / (1000 * 3600 * 24));

            return [
              { text: ddjj.mestributo, style: cellStyle },
              { text: ddjj.rectificacionid == null ? 'Original' : `Rectifica a : ${ddjj.rectificacionid}`, style: cellStyle },
              { text: formatCurrency(ddjj.monto), style: cellStyle },
              { text: ddjj.ddjjConInteres?.interesPorMora ? formatPorcentaje(ddjj.ddjjConInteres.interesPorMora) : '', style: cellStyle }, // Nueva columna para Interés Acumulado
              { text: diferenciaEnDias, style: cellStyle }, // Nueva columna para Días de Mora
              { text: ddjj.ddjjConInteres?.saldoMasDeudaAPagar ? formatCurrency(ddjj.ddjjConInteres.saldoMasDeudaAPagar) : '', style: cellStyle }, // Formatear el monto actualizado
              { text: formatPago(ddjj.pago), style: cellStyle },
            ];
          }),
        ],
        styles: {
          header: {
            fillColor: '#0080ff',
            color: '#ffffff',
            bold: true,
          },
        },
      };

      table.body[0].forEach(header => {
        header.fontSize = 9;
      });

      const content = [];
      let pageMargins = [15, 105, 0, 30];

      if (empresaId == 1) {
        content.push(
          { text: `EMPRESA: ${selectedEmpresa.razon_social}`, alignment: 'left', margin: [60, 110, 0, 0], bold: true },
          { text: `CUIT: ${formatCUIT(selectedEmpresa.cuit)}`, alignment: 'left', margin: [60, 10, 0, 0], bold: true },
        );
        pageMargins = [15, 0, 0, 30]; // Actualizar margen superior si la condición se cumple
      }
      content.push({ table: table, layout: 'lightHorizontalLines', alignment: 'center', margin: [60, 10, 0, 0] })

      const docDefinition = {
        pageSize: 'A3',
        header: {
          image: base64Logo,
          width: 550,
          alignment: 'center'
        },
        content: content,
        footer: {
          image: base64Pie,
          width: 650,
          alignment: 'center'
        },
        pageMargins: pageMargins, // Incrementamos el margen inferior para dar espacio al pie de página
      };
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBlob((blob) => {
        saveAs(blob, 'ListadoDDJJ.pdf');
      });
    } catch (error) {
      console.error('Error al exportar PDF:', error);
      // Manejar el error según sea necesario
    }
  };

  const convertirImagenABase64Async = async (imagen) => {
    return new Promise((resolve, reject) => {
      const lector = new FileReader();
      lector.readAsDataURL(imagen);
      lector.onload = () => {
        resolve(lector.result);
      };
      lector.onerror = (error) => {
        reject(error);
      };
    });
  };


  const estado = [
    { label: "Todos", value: "2" },
    { label: "Pago", value: "1" },
    { label: "Impago", value: "0" }
  ]


  const handlerEstados = (valor) => {
    setPago(valor);
    let status = false;

    productService.getDdjjByStatus(selectedEmpresa.empresaid, valor).then((a) => {
      setProducts(a);
    })
  }

  const dropDown = () => {
    if (admin) {
      return (
        <>
          <div>
            Seleccione la empresa que desea consultar:<br />
            <Dropdown
              value={selectedEmpresa}
              onChange={(e) => selectedempresa(e.value)}
              options={empresas}
              optionLabel="razon_social"
              placeholder="Selecciona la empresa"
              className="w-full md:w-14rem"
              filter
            />
            <Dropdown
              value={pago}
              onChange={(e) => handlerEstados(e.value)}
              options={estado}
              placeholder="Seleccione estado"
              className="w-full md:w-14rem"
              disabled={!selectedEmpresa}
            />
          </div>
        </>
      );
    }
  };


  const MenuAcciones = () => {
    const toast = useRef(null);
    const items = [
      {
        label: 'Exportar a EXCEL',
        icon: PrimeIcons.FILE_EXCEL,
        command: () => {
          exportExcel()
        }
      },
      {
        label: 'Exportar a PDF',
        icon: PrimeIcons.FILE_PDF,
        command: () => {
          exportPDF()
        }
      }
    ];

    return (
      <div>
        <Toast ref={toast}></Toast>
        <SplitButton label="ACCIONES" model={items} className="p-button-sm mr-2 mb-2"></SplitButton>
      </div>
    )
  }



  const header = (
    <>
      <div className="table-header">
        <section>
          <MenuAcciones />
        </section>
        <h5 className="ddjj" >DECLARACIONES JURADAS</h5>
        <section>
          {dropDown()}
        </section>
      </div>
    </>
  );

  const editProduct = (target) => {
    setProductDialog(true);
  };
  const componentRef = useRef();
  const ddjjDialogFooter = (
    <>
      <div>
        {/* <ComponentToPrint ref={componentRef} ddjjInfo={componentRef.ddjjInfo} /> */}
        <ReactToPrint
          trigger={() => <button>Imprimir</button>}
          content={() => componentRef.current}
        />
      </div>
    </>
  );
  const actionBodyTemplate = (products, rowData) => {
    const btnPagarActivo = ultimaRectificativa(products) || false
    const BtnPagar = () => {
      if (products.pago == 0 && btnPagarActivo) {
        return (
          <Button
            icon="pi pi-dollar"
            className="p-button-rounded p-button-success mr-1"
            placeholder="Right"
            onClick={() => payPopUpNow(products)}
            tooltip="Pagar ahora"
            tooltipOptions={{ position: "right" }}
            style={{ width: "30px", height: "30px" }}

          />
        );
      }
    };
    return (
      <>
        <React.Fragment>
          <Button
            icon="pi pi-print"
            className="p-button-rounded p-button-seccondary mr-1"
            placeholder="Top"
            tooltip="Imprimir"
            tooltipOptions={{ position: "top" }}
            onClick={() => printDdjj(products)}
            disabled={products === null && disabledForTime}// }
            style={{ width: "30px", height: "30px" }}
          />

          <BtnPagar />
        </React.Fragment>
      </>
    );
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Actualizar Datos"
        icon="pi pi-check"
        className="p-button-text"
      />
    </React.Fragment>
  );

  const guardoActualizacionPago = () => {
    Swal.fire({
      title: 'Acreditar pago de DDJJ',
      text: "Una vez que acepte, se acreditará el pago seleccionado",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Acreditar Pago'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Operación Exitosa',
          'El pago ha sido acreditado',
          'success'
        )

        const body = {

          metodoPago:
            "Pago acreditado por " +
            nombre +
            "  por el monto de " +
            actualProduct.ddjjConInteres?.saldoMasDeudaAPagar?.toLocaleString("es-AR", { style: "currency", currency: "ARS" }) +
            " en fecha " +
            fechaPagoRecibido,
          idDeclaracion: actualProduct.iddeclaracion,
        };
        productService.payDDJJ(body).then((value) => {
          hideDialogPagoEnEfectivo();
          hideDialogPagarAhora();
          productService.getDeclaracionesJuradas(selectedEmpresa.empresaid).then((data) => {
            setCambioEstadoEmpresa(true)
            setProducts(data);

          });
        });
      }
    })

  };

  const validoCampos = () => {

    if (nombreRecibidorPago === "") {
      //acá va validación
    }
    if (montoRecibido === "") {
      //acá va validación
    }
    if (fechaPagoRecibido === "") {
      //acá va validación
    }
  };


  const statusBodyTemplate = (products) => {
    if (products.pago == 0) {
      return (
        <span
          className={`product-badge status-outofstock`}
          style={{ padding: "0.25em 3rem" }}
        >
          Impago
        </span>
      );
    } else {
      return (
        <span
          className={`product-badge status-instock`}
          style={{ padding: "0.25em 3.6rem" }}
        >
          Pago
        </span>
      );
    }
  }; 
//DEJO ESTA FUNCION COMENTADA, ES PARA QUE LA TABLA MUESTRE QUE UNA DDJJ FUE RECTIFICADA - A CUENTA A FUTURO
/*   const statusBodyTemplate = (product) => {
    const metodoPago = payMethod(product);
  
    if (metodoPago === "Rectificada") {
      return (
        <span
          className={`product-badge status-rectificada`}
          style={{ padding: "0.25em 2.5rem" }}
        >
          Rectificada
        </span>
      );
    } else if (product.pago === 0) {
      return (
        <span
          className={`product-badge status-outofstock`}
          style={{ padding: "0.25em 3rem" }}
        >
          Impago
        </span>
      );
    } else {
      return (
        <span
          className={`product-badge status-instock`}
          style={{ padding: "0.25em 3.6rem" }}
        >
          Pago
        </span>
      );
    }
  };
   */
  



  const rectificationType = (product) => {
    if (product.rectificacionid === null) {
      return "Original";
    } else {
      let rectificationCount = 0; // Inicializar en 0 en lugar de 1
      let previousDeclaration = product;

      while (previousDeclaration.rectificacionid !== null) {
        const rectificationIndex = products.findIndex((item) => item.iddeclaracion === previousDeclaration.rectificacionid);

        if (rectificationIndex !== -1) {
          rectificationCount++;
          previousDeclaration = products[rectificationIndex];
        } else {
          break;
        }
      }

      return `Rectificativa ${rectificationCount}`; // Sumar 1 al contador
    }
  };


  const ultimaRectificativa = (product) => {
    const rectification = products.filter((item) => item.mestributo === product.mestributo);
    if (rectification.length > 0) {

      let ultimoId = -1;
      for (const recti of rectification) {
        const idRectificationActual = recti.iddeclaracion;

        if (idRectificationActual > ultimoId) {
          ultimoId = idRectificationActual;
        }
      }
      if (product.iddeclaracion === ultimoId) {
        return true;
      }
    }
  };

   const payMethod = (products) => {    
    
    if (products.metodopago == null || products.metodopago == "null") {
      return <span>Pendiente de pago</span>;
    } else {
      return products.metodopago;
    }
  }; 

//DEJO ESTA FUNCION COMENTADA, ES PARA QUE LA TABLA MUESTRE QUE UNA DDJJ FUE RECTIFICADA NO DIGA PENDIENTE DE PAGO - A CUENTA A FUTURO
/*   const payMethod = (product) => {
    const existeRectificada = products.some(
      (p) => p.mestributo === product.mestributo && p.rectificacionid !== null
    );
  
    if (existeRectificada && product.rectificacionid === null) {
      return "Rectificada";
    }
  
    if (product.metodopago == null || product.metodopago === "null") {
      return "Pendiente de pago";
    } else {
      return product.metodopago;
    }
  }; */


  const prueba = (value) => {
    setSelectedProducts(value);

  };

  /* FUNCION PARA EXPORTAR EN EXCEL */
  const BtnPagoEmpresa = () => {
    if (!admin) {
      return (
        <>
          {/* BTN MERCADO PAGO */}
          {/* <a href={urlMeli} target="_blank" style={{ textDecoration: "none" }}>
            <img src={mercadoPago} style={{ cursor: "pointer" }} alt="" id="btnAB" />
          </a> */}

          {/* BTN PAGOS 360  */}

          <a href={urlPagos360} target="_blank" style={{ textDecoration: "none" }}>

            <img src={pagos360} alt="" id="btnAB" />
          </a>

          {/* BTN TRANFERENCIA */}
          {/* <img src={transferencia} style={{ cursor: "pointer" }} alt="" id="btnAB" onClick={() => setPagoConTransferencia(true)} /> */}

          {/* BTN PAGO EN EFECTIVO SESION EMPRESA */}

          <img src={soimp} style={{ cursor: "pointer" }} alt="" id="btnAB" onClick={() => setInfoEfectivo(true)} />

          <Dialog header="Información del pago en efectivo"
            visible={infoEfectivo}
            className="p-fluid"
            style={{ width: "50vw", height: "15vw" }}
            modal
            onHide={() => setInfoEfectivo(false)}>
            <p className="m-0">
              Debe ponerse en contacto con el Sindicato a fin de coordinar el pago en efectivo.
              (...)
            </p>
          </Dialog>

          <Dialog
            header="Pago por transferencia"
            visible={pagoConTransferencia}
            className="p-fluid"
            style={{ width: "50vw", height: "15vw" }}
            modal
            onHide={hideDialogPagoConTransferencia}

          >
            {" "}
            <p><strong>Debe transferir a la siguiente cuenta de SOIMP el monto de la DDJJ: </strong></p>
            <p><strong>CUENTA Nº </strong></p>
            <p><strong>CBU Nº </strong></p>
            <p style={{ marginBottom: "0px" }}><strong>Luego debe adjuntar el comprobante de transferencia y enviarlo. Personal del Sindicato acreditará luego el pago de la DDJJ.</strong></p>
            <input
              type="file"
              name="upload"
              id="upload"
              icon="pi pi-file-excel"
              onChange={(e) => fileOnChange(e)}
            />
            <Button
              label="Subir"
              icon="pi pi-check"
              severity="Success"
              id="btnAB"
              width="100"
              onClick={(e) => sendFile()}
            />
            <br />

          </Dialog>
        </>


      )
    } else {
     
      return (
        <>
          <img src={soimp} style={{ cursor: "pointer" }} alt="" id="btnAB" onClick={() => setPagoEnEfectivo(true)} />
          {/*  <Button
            hidden={!admin}
            label="Pago en efectivo"
            icon="pi pi-clock"
            className="p-button-rounded p-button-warning"
            id="btnAB"
            onClick={() => setPagoEnEfectivo(true)} /> */}
        </>

      )
    }

  }

  const DiaPago = () => {
    let fecha = new Date().toLocaleDateString()

    return (
      <>
        <InputText
          id="fecha"
          disabled
          defaultValue={fecha}
          onChange={(e) => {
            setFechaPagoRecibido((e.target.value));
          }}
        />

      </>
    )
  }
  const ValorPago = (product) => {

    return (
      <>
        <InputText
          disabled
          value={actualProduct.ddjjConInteres?.saldoMasDeudaAPagar?.toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
          id="monto"
          aria-describedby="Apellido-help"
          onChange={(e) => onInputChange(e, "monto")}
        />

      </>
    )
  }

  /* MUESTRO EN EL FOOTER DE LA TABLA EL TOTAL DE LAS DDJJ ADEUDADAS */

  const Pie = () => {
    const today = new Date();
    const formattedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    return (
      <>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <span
            style={{
              fontWeight: "bold",
              border: "2px solid",
              borderColor: totalDeclaraciones === 0 ? "green" : "red",
              padding: "10px",
              textAlign: "right"
            }}
          >
            Total adeudado por DDJJ vencidas (monto inicial + intereses) al {formattedDate} = {totalDeclaraciones.toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
          </span>
        </div>
      </>
    )
  }


  return (
    <div className="datatable-crud-demo">

      <Toast ref={toast} />

      <div className="card">
        <DataTable
          ref={dt}
          value={products}
          selection={selectedProducts}
          footer={Pie}
          onSelectionChange={(e) => prueba(e.value)}
          dataKey="iddeclaracion"
          paginator
          rows={10}
          scrollHeight="600px"
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} DDJJ"
          globalFilter={globalFilter}
          header={header}
          responsiveLayout="scroll"
          sortField="fechadeclaracion"
          sortOrder={1}
          emptyMessage="No se encuentran ddjj cargadas"        >

          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
            exportable={false}
          ></Column>
          <Column
            field="mestributo"
            header="Mes tributo"
            sortable
            sortField="fechadeclaracion"
            style={{ minWidth: "16rem" }}
          ></Column>
          <Column
            field="monto"
            header="Monto inicial"
            sortable
            style={{ minWidth: "16rem" }}
            body={(rowData) => rowData.monto.toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
          ></Column>
          <Column
            field="ddjjConInteres.interesPorMora"
            header="Interes acumulado"
            sortable
            style={{ minWidth: "16rem" }}
            body={(rowData) => rowData.ddjjConInteres?.interesPorMora != null ? `${rowData.ddjjConInteres.interesPorMora.toFixed(1)}%` : ''}
          ></Column>
          <Column
            field="ddjjConInteres.deudaAcumulado"
            header="Intereses"
            sortable
            style={{ minWidth: "16rem" }}
            body={(rowData) => {
              const deudaAcumulado = rowData.ddjjConInteres?.deudaAcumulado;
              return deudaAcumulado ? deudaAcumulado.toLocaleString("es-AR", { style: "currency", currency: "ARS" }) : "";
            }}
          ></Column>
          <Column
            header="Días de mora"
            sortable
            style={{ minWidth: "16rem" }}
            body={(rowData) => {
              if (rowData.pago == 0) {
                const fechaDeclaracion = new Date(rowData.fechadeclaracion);
                const fechaActual = new Date();
                const diferenciaEnTiempo = fechaActual - fechaDeclaracion;
                const diferenciaEnDias = Math.floor(diferenciaEnTiempo / (1000 * 3600 * 24));
                return diferenciaEnDias >= 0 ? diferenciaEnDias : "";
              }
              return "";
            }}
          />

          <Column
            field="ddjjConInteres.saldoMasDeudaAPagar"
            header="Total a pagar"
            sortable
            style={{ minWidth: "16rem" }}
            body={(rowData) => rowData.ddjjConInteres?.saldoMasDeudaAPagar?.toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
          ></Column>

          <Column
            field="iddeclaracion"
            header="Tipo"
            body={rectificationType}
            sortable
            style={{ minWidth: "16rem" }}
          ></Column>
          <Column
            field="pago"
            header="Estado"
            body={statusBodyTemplate}
            sortable
            style={{ minWidth: "16rem" }}
          ></Column>
          <Column
            field="metodopago"
            header="Metodo de pago"
            body={payMethod}
            sortable
            style={{ minWidth: "16rem" }}
          ></Column>
          <Column
            header="Panel de acciones"
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={productDialog}
        style={{ width: "1000px", textAlign: "center" }}
        header="Rectificar DDJJ"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      ></Dialog>

      <Dialog
        header="Metodos de pago"
        visible={paymentNow}
        className="p-fluid"
        style={{ width: "50vw" }}
        modal
        onHide={hideDialogPagarAhora}
      >
        <strong><p>Seleccione el metodo de pago mediante el cual desea abonar la DDJJ</p></strong>

        <div className="contenedorBotonesBilleteras">

          <BtnPagoEmpresa />

          {/* BTN PAGO EN EFECTIVO SESION SINDICATO */}


        </div>
      </Dialog>


      <Dialog
        visible={printDdjjDialog}
        style={{ width: "1000px", textAlign: "center" }}
        modal
        className="p-fluid"
        footer={ddjjDialogFooter}
        onHide={hideDialog}
      >
        {" "}

        <ComponentToPrint ref={componentRef} ddjjInfo={ddjjInfo} products={products} interest={interesesDdjj} />

      </Dialog>



      <Dialog
        header="Acreditar pago en Sindicato"
        visible={pagoEnEfectivo}
        className="p-fluid"
        style={{ width: "50vw" }}
        modal
        onHide={hideDialogPagoEnEfectivo}
      >
        <div className="flex flex-column gap-2">
          <label htmlFor="nombre">Responsable</label>
          <InputText
            id="nombre"
            aria-describedby="Nombre-help"
            onChange={(e) => onInputChange(e, "nombre")}
            value={nombre}
            disabled={true}
          />
          <label htmlFor="monto">Monto</label>
          <ValorPago />
          <label htmlFor="fecha">Fecha</label>
          <DiaPago />
          <Button
            disabled={validoCampos()}
            onClick={() => guardoActualizacionPago()}
          >
            ACREDITAR PAGO
          </Button>
        </div>
      </Dialog>

    </div>
  );
};

export default Ddjj;
