import { Button } from 'primereact/button';
import React from 'react';
import Swal from 'sweetalert2';
import { ProductService } from '../../service/ProductService';

const TablaIntereses = ({ intereses, fetchIntereses, loading }) => {
    const productService = new ProductService();

    const eliminarTasa = async (id) => {

        Swal.fire({
            title: `¿DESEA ELIMINAR EL INTRÉS SELECCIONADO?`,
            icon: 'warning',
            showDenyButton: true,
            denyButtonText: 'CANCELAR',
            cancelButtonColor: 'red',
            confirmButtonColor: 'green',
            confirmButtonText: 'ELIMINAR'

        }).then((result) => {
            if (result.isConfirmed) {

                productService.deleteInteresCargado(id).then((value) => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'INTERÉS ELIMINADO CORRECTAMENTE',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    fetchIntereses(); 
                });
            } else if (result.isDenied) {
              Swal.fire("ELIMINACION CANCELADA")
            }
          })
        
    };
        


    return (
        <div>
            <h4 style={{ textAlign: "center", marginTop: "25px" }}>TABLA HISTÓRICA DE INTERESES</h4>
            {loading ? (
                <p style={{ textAlign: 'center' }}>Cargando intereses...</p>
            ) : (
                <div style={{
                    maxHeight: '400px',
                    overflowY: 'auto',
                    margin: '20px auto',
                    width: '100%',
                    boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    backgroundColor: '#fff'
                }}>
                    <table
                        style={{
                            borderCollapse: 'collapse',
                            width: '100%',
                            fontSize: '1rem',
                            fontFamily: 'Arial, sans-serif',
                            color: '#222',
                            borderRadius: '10px',
                            overflow: 'hidden'
                        }}
                    >
                        <thead
                            style={{
                                backgroundColor: '#333',
                                color: '#fff',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            <tr>
                                <th style={{ padding: '10px' }}>Fecha</th>
                                <th style={{ padding: '10px' }}>Interes</th>
                                <th style={{ padding: '10px' }}>Resolución</th>
                                <th style={{ padding: '10px' }}>Accion</th>
                            </tr>
                        </thead>
                        <tbody>
                            {intereses.map((item, index) => (
                                <tr key={item.id} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                                    <td style={{ padding: '12px 15px', textAlign: 'center' }}>{new Date(item.mesanio).toLocaleDateString()}</td>
                                    <td style={{ padding: '12px 15px', textAlign: 'center' }}>{item.interes} %</td>
                                    <td style={{ padding: '12px 15px', textAlign: 'center' }}>{item.resolucion}</td>
                                    <td style={{ padding: '12px 15px', textAlign: 'center' }}>
                                        <Button icon="pi pi-trash" onClick={() => eliminarTasa(item.id)} style={{ pointerEvents: 'auto' }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default TablaIntereses;
