import React from "react";
import { FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { useParams,useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ProductService } from "../../service/ProductService";
import { useState } from "react";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Swal from 'sweetalert2'

const ChangePws = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const _service = new ProductService();
  const [changeActivo, setChangeActivo] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [userIdActual, setUserId] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    _service.getActivoCambioPassword(id).then((actual) => {
      const { activo, userId } = actual;
      setChangeActivo(activo);
      setUserId(userId)
    });
  }, []);
  const changePassword1 = (value) => {
    setPassword1(value)
  }
  const changePassword2 = (value) => {
    setPassword2(value)
  }
  const handleChangePassword = () => {
    if (password1 === password2) {
      const body = {
        user: {
          password: password1,
          id: userIdActual
        },
        idGmail: id
      }
      _service.updatePassword(body).then((response) => {
        Swal.fire({
          icon: 'success',
          text: 'Contraseña cambiada con exito.',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/auth");
          }
        });
      })
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Debe ingresar la misma contraseña en ambos campos.',
      })
    }
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        mt: 9,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        minHeight={100}
        sx={{
          maxWidth: "500px",
          border: "1px solid #F5F5F5",
          borderRadius: "13px;",
          boxShadow:
            "-10px 2px 13px 2px #00000033, 10px 2px 13px 2px #00000033, -50px 22px 15px -30px rgba(0,0,0,0);",
          backgroundColor: "#F5F5F5",
          display: "flex",
        }}
        alignItems="center"
        justifyContent={"center"}
      >
        <Grid
          sx={{ marginTop: "15px" }}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <AccountCircleIcon
            sx={{
              fontSize: "50px",
            }}
          />
        </Grid>
        <Grid justifyContent="center" xs={12} display="flex" sx={{ mt: 1 }}>
          <Typography variant="h5" gutterBottom>
            Recuperar Contraseña
          </Typography>
        </Grid>


        <Grid justifyContent="center">

          <FormControl onChange={(newValue) => changePassword1(newValue.target.value)} sx={{ mt: 2.5, width: '52ch', }} variant="outlined">

            <InputLabel
              htmlFor="outlined-adornment-password1">Nueva contraseña</InputLabel>

            <OutlinedInput
              id="outlined-adornment-password1"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }

              label="Nueva contraseña"
            />
          </FormControl>

        </Grid>

        <Grid justifyContent="center">

          <FormControl onChange={(newValue) => changePassword2(newValue.target.value)} sx={{ mt: 2.5, width: '52ch', }} variant="outlined">

            <InputLabel
              error={password1 !== password2 ? true : false}
              htmlFor="outlined-adornment-password2">Repita la contraseña

            </InputLabel>

            <OutlinedInput

              id="outlined-adornment-password2"
              type={showPassword ? 'text' : 'password'}
              error={password1 !== password2 ? true : false}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"

                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }

              label="Repita la contraseña"
            />
            <FormHelperText error={password1 !== password2 ? true : false}>
              Ingrese la misma contraseña en ambos campos.
            </FormHelperText>
          </FormControl>

        </Grid>






        <Grid
          sx={{
            marginBottom: "20px",
          }}
          display="flex"
          justifyContent="center"
          xs={12}
        >
          <Button
            onClick={handleChangePassword}
            sx={{ ml: 1, mt: 2, width: "189px" }}
            variant="contained"
            endIcon={<SendIcon />}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChangePws;
























/* import { Grid, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { ProductService } from "../../service/ProductService";
import { useState } from "react";
const ChangePws = () => {
  const { id } = useParams();
  const _service = new ProductService();
  const [changeActivo, setChangeActivo] = useState(false);
  const [password1,setPassword1] = useState("");
  const [password2,setPassword2] = useState("");
  const [userIdActual, setUserId] = useState("")
  useEffect(() => {
    console.log("ENTRO")
    _service.getActivoCambioPassword(id).then((actual) => {
      
      const { activo,userId } = actual;
      console.log(activo,userId)
      setChangeActivo(activo);
      setUserId(userId)
    });
  }, []);
  const changePassword1 =(value) => {
    setPassword1(value)
  }
  const changePassword2 =(value) => {
    setPassword2(value)
  }
  const handleChangePassword = () => {
    if (password1 === password2){
        const body = {
            user : {
                password : password1,
                id : userIdActual
            },
            idGmail : id
        }
        console.log("soy el body",body.user)
        _service.updatePassword(body).then((response)=> {
            console.log(response)
        })
    }else{
       // alerto de que las password no son iguales
    }
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        mt: 9,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        minHeight={100}
        sx={{
          maxWidth: "500px",
          border: "1px solid #F5F5F5",
          borderRadius: "13px;",
          boxShadow:
            "-10px 2px 13px 2px #00000033, 10px 2px 13px 2px #00000033, -50px 22px 15px -30px rgba(0,0,0,0);",
          backgroundColor: "#F5F5F5",
          display: "flex",
        }}
        alignItems="center"
      >
        <Grid
          sx={{ marginTop: "15px" }}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <AccountCircleIcon
            sx={{
              fontSize: "50px",
            }}
          />
        </Grid>
        <Grid justifyContent="center" xs={12} display="flex" sx={{ mt: 1 }}>
          <Typography variant="h5" gutterBottom>
            Recuperar Contraseña
          </Typography>
        </Grid>
        <Grid
          display="flex"
          justifyContent="center"
          xs={12}
          component="form"
          sx={{
            "& > :not(style)": { mt: 2.5, width: "52ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            type="password"
            id="outlined-basic"
            label="Nueva contraseña"
            variant="outlined"
            onChange={(newValue) => changePassword1(newValue.target.value)}
          />
        </Grid>
        <Grid
          display="flex"
          justifyContent="center"
          xs={12}
          component="form"
          sx={{
            "& > :not(style)": { mt: 2.5, width: "52ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            type="password"
            id="outlined-basic 1"
            label="Repita la contraseña"
            variant="outlined"
            onChange={(newValue) => changePassword2(newValue.target.value)}
          />
        </Grid>

        <Grid
          sx={{
            marginBottom: "20px",
          }}
          display="flex"
          justifyContent="center"
          xs={12}
        >
          <Button
            onClick={handleChangePassword}
            sx={{ ml: 1, mt: 2, width: "189px" }}
            variant="contained"
            endIcon={<SendIcon />}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChangePws;
 */