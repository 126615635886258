import React, { createContext, useState } from "react";
import { Navigate } from "react-router-dom";
export const UserContext = createContext({});
export const UserProvider = ({ children }) => {
    const [auth,setAuth] = useState(null);
    const [session, setSession] = useState(null);
    const [login, setLogin] = useState(false);
    const setSessionInLocalStorage = ({dateLoggin,idSession,empresaId,admin,empresa,nombre,userId,accessToken}) => {
        if (admin == 1 ){
            admin = true;
        }
        localStorage.setItem("session",JSON.stringify({dateLoggin: dateLoggin,idSession:idSession,empresa: empresa, empresaId:empresaId,admin:admin,nombre:nombre,userId:userId,accessToken:accessToken}))
        setSession({dateLoggin: dateLoggin,idSession:idSession,empresaId:empresaId})
        setAuth(true)       
    }

    const setLoggin= () => {
        setLogin(login ? false : true)
        window.location.reload(false);
    }
    const logOut = () => {
        localStorage.removeItem("session")
        localStorage.removeItem("horaUltimaActividad");
        setSession(null)
        setAuth(false)
        setLoggin()
        Navigate('/auth')
    }


    const getAuth = () => {
        return localStorage.getItem("session") ?? '';
    }
    return (<UserContext.Provider value={{setSessionInLocalStorage,session,auth,getAuth,logOut,setLoggin,login}}>
        {children}
    </UserContext.Provider>)

}