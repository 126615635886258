import React, { useState } from 'react';
import { ProductService } from '../../service/ProductService';
import { TextField } from '@mui/material';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2';

const InputIntereses = ({ fetchIntereses, intereses }) => {   
    const [porcentaje, setPorcentaje] = useState(0);
    const [fechaSeleccionada, setFechaSeleccionada] = useState('');
    const [resolucion, setResolucion] = useState('');
    const service = new ProductService();

    const existe = intereses.some(interes => {       
        const fechaInteres = new Date(interes.mesanio);      
        const [dia, mes, anio] = fechaSeleccionada.split('/');
        const fechaSeleccionadaDate = new Date(anio, mes - 1, dia);    
        return fechaSeleccionadaDate.getTime() === fechaInteres.getTime();
      });

    const sendPercentage = () => {       
        if (existe) {
            Swal.fire({
                icon: 'error',
                title: 'Interés ya registrado',
                text: `Ya existe un interés registrado para la fecha ${fechaSeleccionada}.`,
            });
            return;
        } 
        const body = {
            interes: porcentaje,
            mesAnio: fechaSeleccionada,
            resolucion: resolucion,
        };
        Swal.fire({
            title: `¿DESEA INGRESAR PARA LA FECHA ${fechaSeleccionada} TASA DE ${porcentaje}%?`,
            icon: 'warning',
            showDenyButton: true,
            denyButtonText: 'CANCELAR',
            cancelButtonColor: 'red',
            confirmButtonColor: 'green',
            confirmButtonText: 'GUARDAR TASA'

        }).then((result) => {
            if (result.isConfirmed) {
                service.setIntereses(body).then((value) => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'TASA INGRESADA CORRECTAMENTE',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    fetchIntereses();
                });
            } else if (result.isDenied) {
                Swal.fire("INGRESO CACELADO")
            }
        })
    };


    const isFormValid = () => {
        return porcentaje !== '' && fechaSeleccionada !== '' && fechaSeleccionada !== 'Invalid Date' && resolucion !== '';
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
            <h4 style={{ marginBottom: '25px' }}>CARGA DE INTERESES</h4>
            <TextField
                onChange={(e) => {
                    const newValue = e.target.value.replace(',', '.');
                    if (newValue === '') {
                        setPorcentaje('');
                    } else {
                        const parsedValue = parseFloat(newValue);
                        if (!isNaN(parsedValue)) {
                            setPorcentaje(newValue);
                        }
                    }
                }}
                onKeyPress={(e) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                        e.preventDefault();
                    }
                }}
                value={porcentaje}
                multiline
                rows={1}
                label="Ingrese la tasa"
                variant="outlined"
                style={{ width: '300px', marginBottom: '20px' }}
            />
            <TextField
                type="date"
                onChange={(e) => {
                    const currentDate = new Date();
                    const fechaSeleccionada = new Date(e.target.value);
                    if (fechaSeleccionada > currentDate) {
                        e.target.value = ''; // Limpiar el valor seleccionado
                        Swal.fire({
                            icon: 'error',
                            title: 'Selección incorrecta',
                            text: 'No se puede seleccionar una fecha futura',
                        });
                    } else {
                        fechaSeleccionada.setDate(fechaSeleccionada.getDate() + 1);
                        setFechaSeleccionada(fechaSeleccionada.toLocaleDateString());
                    }
                }}
                label="Entrada en vigencia"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                style={{ marginBottom: '20px', width: '300px' }}
            />
            <TextField
                onChange={(e) => setResolucion(e.target.value)}
                multiline
                rows={3}
                label="Ingrese la Resolución que establece la tasa"
                variant="outlined"
                style={{ width: '300px', marginBottom: '20px' }}
            />
            <Button
                variant="contained"
                color="primary"
                disabled={!isFormValid()}
                onClick={sendPercentage}
                style={{
                    marginTop: '20px',
                    width: '300px',
                    backgroundColor: '#6c63ff',
                    color: '#fff',
                    border: 'none',
                    fontSize: '1rem',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                GUARDAR
            </Button>
        </div>
    );
};

export default InputIntereses;
