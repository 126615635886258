/* SWEET ALERT PARA MOSTRAR EN CUALQUIER COMPONENTE CUANDO SE QUIERA EXPORTAR A PDF O EXCEL SIN HABER SELECCIONADO FILAS */

import Swal from "sweetalert2";

const AlertExport = () => {
    Swal.fire({
        icon: 'error',
        title: `SELECCION INCOMPLETA`,
        text: ` Debe seleccionar las filas `,
      })
  
  return null
  
}
export default AlertExport