import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../index.css";
import React, { useState, useEffect, useRef } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { CustomerService } from "../service/CustomerService";
import { empresaId } from "../components/utils/LocalStorage";
import "./TablaEmpresas.css";
import { ProductService } from "../service/ProductService";
import Swal from 'sweetalert2'
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import Input from "@mui/joy/Input";
import { SplitButton } from 'primereact/splitbutton';
import { PrimeIcons } from 'primereact/api';
import { Toast } from "primereact/toast";
import { Dropdown } from 'primereact/dropdown';
import { exportExcel, exportPdf } from './exports'


const TablaEmpresas = () => {
  let business = {
    cod_postal: "",
    cuit: "",
    departamento: "",
    direccion: "",
    email: "",
    inicio_activ_empleador: "",
    inicio_afip: "",
    localidad: "",
    provincia: "",
    rama: "",
    razon_social: "",
    telefono: "",
    contador_mail: "",
    empresaId: 0
  };

  const [customers, setCustomers] = useState(null);
  const [customer, setCustomer] = useState(business);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const productService = new ProductService(empresaId);
  const navigate = useNavigate();
  const [productDialog, setProductDialog] = useState(false);
  const [emailError, setEmailError] = useState(false);

  /* ACA SE ESTABLECEN LOS FILTROS DE CADA COLUMNA DE LA TABLA */

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    razon_social: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    direccion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    cuit: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    rama: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    telefono: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    cuota: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);


  const customerService = new CustomerService();

  useEffect(() => {
    customerService.getCustomersLarge().then((data) => {
      setCustomers(getCustomers(data));
      setLoading(false);
    });
  }, []);

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.date = new Date(d.date);
      let cuit = d.cuit.toString();
      // Verifica si ya está formateado
      if (!/^(\d{2})-(\d+)-(\d{1})$/.test(cuit)) {
        d.cuit = `${cuit.slice(0, 2)}-${cuit.slice(2, -1)}-${cuit.slice(-1)}`;
      }
      return d;
    });
  }

  /* FUNCION DE LA LIBRERIA DE LA TABLA (PRIMEREACT) PARA LOS FILTROS DE LAS COLUMAS */

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const MenuAcciones = () => {
    const toast = useRef(null);
    const items = [
      {
        label: 'Exportar a EXCEL',
        icon: PrimeIcons.FILE_EXCEL,
        command: () => {
          exportExcel(selectedCustomers)
        }
      },
      {
        label: 'Exportar a PDF',
        icon: PrimeIcons.FILE_PDF,
        command: () => {
          exportPdf(selectedCustomers)
        }
      }
    ];

    return (
      <div>
        <Toast ref={toast}></Toast>
        <SplitButton label="ACCIONES" model={items} className="p-button-sm mr-2 mb-2"></SplitButton>
      </div>
    )
  }



  /* FUNCION QUE DEVUELVE EL HEADER DE LA TABLA */

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <MenuAcciones />
        <h5 className="m-0">LISTADO DE EMPRESAS</h5>

        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Busqueda avanzada"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  const editProduct = (customer) => {
    setProductDialog(true);

    setCustomer({ ...customer });
  };

  const isValidForm = (
    customer.cod_postal === "" ||
    customer.cuit === "" ||
    customer.cuit.length < 10 ||
    customer.direccion === "" ||
    customer.email === "" ||
    customer.razon_social === "" ||
    customer.telefono === "" ||
    customer.localidad === "" ||
    customer.contador_mail === ""
  )

  const saveBusiness = () => {   
    if (isValidForm) {
      Swal.fire({
        icon: 'error',
        title: 'Ingreso incorrecto',
        text: 'Todos los campos son obligatorios',
      })

    } else {
      const body = {
        business: customer
      };     
      

      if (customers?.length > 0) {
        const cuitParseadobody = `${body.business.cuit.slice(0, 2)}-${body.business.cuit.slice(2, -1)}-${body.business.cuit.slice(-1)}`;
        const existe = customers.some(dato => {   
      return cuitParseadobody === dato.cuit;
        });        
        if (existe) {
          Swal.fire({
            icon: 'error',
            title: `Ya existe el CUIT registrado`,
          });
          return;
        }
      }

      delete body.business.date;
      body.business.inicio_afip = new Date(customer.inicio_afip.split('/').reverse().join('-')).toISOString().split('T')[0];
      body.business.inicio_activ_empleador = new Date(customer.inicio_activ_empleador.split('/').reverse().join('-')).toISOString().split('T')[0];
      body.business.cuit = body.business.cuit.replace(/-/g, '');

      productService.editarEmpresa(body)
        .then(() => {
          hideDialog();
          Swal.fire({
            title: "Empresa actualizada correctamente!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "green",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              customerService.getCustomersLarge().then((data) => {
                setCustomers(getCustomers(data));
                setLoading(false);
              });
            }
          });
        })
        .catch((err) => {

        });
    }
  }


  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          onClick={() => editProduct(rowData)}
          icon="pi pi-pencil"
          aria-label="Submit"
          className="p-button-rounded p-button-success"
          style={{ width: "30px", height: "30px" }}
        />
        <Button
          id="btnOjo"
          icon="pi pi-eye"
          rounded
          aria-label="Submit"
          raised
          className="p-button-rounded p-button-secondary"
          onClick={() => navigate(`/empleados?empresaid=${rowData.EmpresaId}&razonsocial=${rowData.razon_social}&cuit=${rowData.cuit}`, { replace: true })}
          style={{ width: "30px", height: "30px" }}
        />

      </React.Fragment>
    );
  };

  const hideDialog = () => {
    /* setSubmitted(false);*/
    setProductDialog(false);
  };
  const onInputChange = (e, name) => {
    if (name === 'departamento') {
      const value = e.target.value;
      let _customer = { ...customer };
      _customer[`${name}`] = value;
      _customer['localidad'] = "";
      setCustomer(_customer)

    } else {
      const value = e.target.value;

      let _customer = { ...customer };
      _customer[`${name}`] = value;
      setCustomer(_customer);


      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (name === 'email' && !emailRegex.test(value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }

  };

  const productDialogFooter = (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        <Button
          label="Cancelar"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
        />
        <Button
          label="Actualizar datos"
          icon="pi pi-check"
          className="p-button-text"
          onClick={saveBusiness}
          disabled={isValidForm ? true : false}
        />
      </div>
    </React.Fragment>
  );

  const ramas = [
    "CARPINTERIA",
    "ASERRADERO",
    "TERCIADO",
    "AGLOMERADO"
  ];
  const departamentos = [
    "APOSTOLES",
    "CAINGUAS",
    "CANDELARIA",
    "CAPITAL",
    "CONCEPCION",    
    "LIBERTADOR SAN MARTIN",
    "LEANDRO N. ALEM",
    "OBERA",
    "SAN IGNACIO",
    "SAN JAVIER",
    "VEINTICINCO DE MAYO"
  ];

  const localidadesPorDepartamento = {
    "APOSTOLES": ["APOSTOLES", "AZARA", "SAN JOSE", "TRES CAPONES"],
    "CAINGUAS": ["ARISTOBULO DEL VALLE", "CAMPO GRANDE", "2 DE MAYO"],
    "CANDELARIA": ["BONPLAND", "CANDELARIA", "CERRO CORA", "LORETO", "MARTIRES", "PROFUNDIDAD", "SANTA ANA"],
    "CAPITAL": ["FACHINAL", "GARUPA", "POSADAS"],
    "CONCEPCION": ["CONCEPCION DE LA SIERRA", "SANTA MARIA"],  
    "LIBERTADOR SAN MARTIN": ["CAPIOVI", "EL ALCAZAR", "GARUHAPE", "PUERTO LEONI", "PUERTO RICO", "RUIZ DE MONTOYA"],
    "LEANDRO N. ALEM": ["ALMAFUERTE", "ARROYO DEL MEDIO", "CAA-YARI", "CERRO AZUL", "DOS ARROYOS", "GOBERNADOR LOPEZ", "LEANDRO N. ALEM", "OLEGARIO VICTOR ANDRADE"],
    "OBERA": ["CAMPO RAMON", "CAMPO VIERA", "COLONIA ALBERDI", "GENERAL ALVEAR", "GUARANI", "LOS HELECHOS", "OBERA", "PANAMBI", "SAN MARTIN"],
    "SAN IGNACIO": ["COLONIA POLANA", "CORPUS", "GENERAL URQUIZA", "GOBERNADOR ROCA", "HIPOLITO IRIGOYEN", "JARDIN AMERICA", "SAN IGNACIO", "SANTO PIPO"],
    "SAN JAVIER": ["FLORENTINO AMEGHINO", "ITACARUARE", "MOJON GRANDE", "SAN JAVIER"],
    "VEINTICINCO DE MAYO": ["ALBA POSSE", "COLONIA AURORA", "25 DE MAYO", "COLONIA ALICIA"]
  };

  const DevuelvoLocalidades = (param) => {
    if (customer.departamento === "" && customer?.localidad == null) {
      return (
        <>
          <div className="flex flex-column gap-2">
            <label htmlFor="localidad">Localidad</label>
            <Dropdown
              disabled
              placeholder="Debe primero elegir el Departamento"
            />
          </div>
        </>
      );
    } else {
      let dptoActual = customer.departamento;

      const localidades = localidadesPorDepartamento[dptoActual] || [];

      return (
        <>
          <div className="flex flex-column gap-2">
            <label htmlFor="localidad">Localidad</label>
            <Dropdown
              value={customer.localidad || customer?.localidad}
              defaultValue={customer.localidad || customer?.localidad}
              onChange={(e) => { onInputChange(e, "localidad"); }}
              options={localidades}
              placeholder="Seleccione la localidad"
            />
          </div>
        </>
      );
    }
  };


  return (
    <>
      <div className="datatable-doc-demo">
        <DataTable
          value={customers}
          paginator
          className="p-datatable-customers"
          header={header}
          rows={25}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50, 100]}
          dataKey="EmpresaId"
          rowHover
          selection={selectedCustomers}
          scrollHeight="600px"
          onSelectionChange={(e) => setSelectedCustomers(e.value)}
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          size="medium"
          responsiveLayout="scroll"
          globalFilterFields={[
            "razon_social",
            "direccion",
            "localidad",
            "departamento",
            "telefono",
            "cuit",
            "rama",
            "representative.name",
            "contador",
            "cuota",
            "status",
            "cod_postal"
          ]}
          emptyMessage="No se encuentran empresas cargadas"
          currentPageReportTemplate="Mostrando {last} de {totalRecords} empresas"
        >
          <Column
            selectionMode="multiple"
            selectionAriaLabel="name"
            headerStyle={{ width: "3em" }}
          />
          <Column
            field="razon_social"
            header="Razón Social"
            sortable
            style={{ minWidth: "10rem" }}
          />
          <Column
            field="cuit"
            header="CUIT"
            sortable
            filterField="cuit"
            style={{ minWidth: "10rem" }}
          />
          <Column
            field="rama"
            header="Rama"
            sortable
            filterPlaceholder="Buscar por e-mail"
            style={{ minWidth: "10rem" }}
          />
          <Column
            field="provincia"
            header="Provincia"
            sortable
            style={{ minWidth: "7rem" }}
          />
          <Column
            field="direccion"
            header="Direccion"
            sortable
            style={{ minWidth: "10rem" }}
          />
          <Column
            field="departamento"
            header="Departamento"
            sortable
            style={{ minWidth: "10rem" }}
          />
          <Column
            field="localidad"
            header="Localidad"
            sortable
            style={{ minWidth: "10rem" }}
          />
          <Column
            field="cod_postal"
            header="CP"
            sortable
            style={{ minWidth: "1rem" }}
          />
          <Column
            body={(rowData) => {
              const nombre = rowData.contador_nombre || '';
              const apellido = rowData.contador_apellido || '';
              return `${nombre} ${apellido}`;
            }}
            header="Contador"
            sortable
            style={{ minWidth: "3rem" }}
          />
          <Column
            body={(rowData) => {
              const direccion = rowData.contador_direccion || '';
              return direccion;
            }}
            header="Dirección Contador"
            sortable
            style={{ minWidth: "1rem" }}
          />
          <Column
            body={(rowData) => {
              const telefono = rowData.contador_telefono || '';
              return telefono;
            }}
            header="Teléfono Contador"
            sortable
            style={{ minWidth: "1rem" }}
          />
          <Column
            body={(rowData) => {
              const mail = rowData.contador_mail || '';
              return mail;
            }}
            header="Mail Contador"
            sortable
            style={{ minWidth: "1rem" }}
          />
          <Column
            field="inicio_afip"
            header="Inicio AFIP"
            sortable
            style={{ minWidth: "7rem" }}
            body={(rowData) => {
              const startDate = new Date(rowData.inicio_afip);
              const nextDay = new Date(startDate.setDate(startDate.getDate() + 1));
              return nextDay.toLocaleDateString();
            }}
          />
          <Column
            field="inicio_activ_empleador"
            header="Inicio Empleador"
            sortable
            style={{ minWidth: "8rem" }}
            body={(rowData) => {
              const startDate = new Date(rowData.inicio_activ_empleador);
              const nextDay = new Date(startDate.setDate(startDate.getDate() + 1));
              return nextDay.toLocaleDateString();
            }}
          />
          <Column
            field="telefono"
            header="Teléfono"
            sortable
            style={{ minWidth: "7rem" }}
          />
          <Column
            field="email"
            header="E-Mail"
            sortable
            style={{ minWidth: "12rem" }}
            bodyStyle={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          />
          <Column
            body={actionBodyTemplate}
            header="Panel de Acciones"
            style={{ minWidth: "7rem" }}
            exportable={false}
          />
        </DataTable>

        <Dialog
          visible={productDialog}
          style={{ width: "470px" }}
          header="Editar datos de la empresa"
          modal
          className="p-fluid"
          footer={productDialogFooter}
          onHide={hideDialog}
        >
          <div>
            <label htmlFor="cuit">CUIT - sin guiones -</label>

            <InputText
              value={typeof customer.cuit === 'string' ? customer.cuit.replace(/-/g, "") : ""}
              /* defaultValue={customer.cuit} */
              id="cuit"
              keyfilter="num"
              aria-describedby="cuit-help"
              maxLength={11}
              onChange={(e) => onInputChange(e, "cuit")}
              className={!customer.cuit || customer.cuit.length < 10 ? "p-invalid" : ""}
            />
            {!customer.cuit || customer.cuit.length < 10 ? <small className="p-error">Campo requerido</small> : ""}
          </div>

          <div>
            <label htmlFor="razsoc">Razón Social</label>
            <InputText
              defaultValue={customer?.razon_social}
              id="razsoc"
              aria-describedby="razsoc-help"
              onChange={(e) => {
                onInputChange(e, "razon_social");
              }}
              className={!customer.razon_social ? "p-invalid" : ""}
            />
            {!customer.razon_social ? <small className="p-error">Campo requerido</small> : ""}
          </div>

          <div>
            <label htmlFor="email">Email</label>
            <InputText
              defaultValue={customer.email}
              onChange={(e) => onInputChange(e, "email")}
              id="email"
              aria-describedby="email-help"
              className={!customer.email ? "p-invalid" : ""}
            />
            {!customer.email ? <small className="p-error">Campo requerido</small> : ""}
          </div>

          <div>
            <label htmlFor="telefono">Teléfono</label>
            <InputText
              defaultValue={customer?.telefono}
              id="telefono"
              keyfilter="num"
              aria-describedby="telefono-help"
              onChange={(e) => {
                onInputChange(e, "telefono");
              }}
              className={!customer.telefono ? "p-invalid" : ""}
            />
            {!customer.telefono ? <small className="p-error">Campo requerido</small> : ""}
          </div>

          <div>
            <label htmlFor="direccion">Dirección</label>
            <InputText
              defaultValue={customer.direccion}
              onChange={(e) => onInputChange(e, "direccion")}
              className={!customer.direccion ? "p-invalid" : ""}
            />
            {!customer.direccion ? <small className="p-error">Campo requerido</small> : ""}
          </div>

          <label htmlFor="departamento">Departamento</label>
          <Dropdown
            value={customer.departamento}
            onChange={(e) => onInputChange(e, "departamento")}
            options={departamentos}
            placeholder="Seleccione el departamento"
          />

          <DevuelvoLocalidades />

          <label htmlFor="provincia">Provincia</label>
          <InputText
            disabled
            defaultValue={customer?.provincia}
            id="provincia"
            aria-describedby="provincia-help"
            onChange={(e) => {
              onInputChange(e, "provincia");
            }}
          />

          <div>
            <label htmlFor="codpostal">Código Postal</label>
            <InputText
              defaultValue={customer.cod_postal}
              onChange={(e) => onInputChange(e, "cod_postal")}
              className={!customer.cod_postal ? "p-invalid" : ""}
            />
            {!customer.cod_postal ? <small className="p-error">Campo requerido</small> : ""}
          </div>

          <label htmlFor="rama">Rama</label>
          <Dropdown
            value={customer?.rama}
            onChange={(e) => {
              onInputChange(e, "rama");
            }}
            options={ramas}
            placeholder="Seleccione la rama"
          />


          <div>
            <label htmlFor="inicioactividad">Fecha de inicio de actividades</label>
          </div>
          <Input
            onChange={(e) => {
              const selectedDate = new Date(e.target.value);
              const currentDate = new Date();

              if (selectedDate > currentDate) {
                e.target.value = ""; // Limpiar el valor seleccionado
                Swal.fire({
                  icon: 'error',
                  title: 'Selección incorrecta',
                  text: 'No se puede seleccionar una fecha futura',
                })
              } else {
                onInputChange(e, "inicio_activ_empleador"); // Actualizar el estado con la fecha seleccionada
              }
            }}
            required
            type="date"
            fullWidth
            defaultValue={
              customer?.inicio_activ_empleador
                ? new Date(customer.inicio_activ_empleador)
                  .toISOString()
                  .split("T")[0]
                : ""
            } />
          <div>
            <label htmlFor="inicioafip">Inicio de Actividad AFIP</label>
          </div>
          <Input
            onChange={(e) => {
              const selectedDate = new Date(e.target.value);
              const currentDate = new Date();

              if (selectedDate > currentDate) {
                e.target.value = ""; // Limpiar el valor seleccionado
                Swal.fire({
                  icon: 'error',
                  title: 'Selección incorrecta',
                  text: 'No se puede seleccionar una fecha futura',
                })
              } else {
                onInputChange(e, "inicio_afip"); // Actualizar el estado con la fecha seleccionada
              }
            }}
            required
            type="date"
            fullWidth
            defaultValue={
              customer?.inicio_afip
                ? new Date(customer.inicio_afip).toISOString().split("T")[0]
                : ""
            }
          />
          <div>
            <label htmlFor="codpostal">Mail Contador</label>
            <InputText
              defaultValue={customer.contador_mail}
              onChange={(e) => onInputChange(e, "contador_mail")}
              className={!customer.contador_mail ? "p-invalid" : ""}
            />
            {!customer.contador_mail ? <small className="p-error">Campo requerido</small> : ""}
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default TablaEmpresas;
